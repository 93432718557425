import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Heading from '../components/Heading'
import HomeHeader from '../layouts/headers/HomeHeader'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'

const Privacy: React.FC = () => {
  return (
    <Fragment>
      <HomeHeader />
      <StyledBackground>
        <StyledContainer>
          <Heading
            headline="Privacy Policy"
            subheadline="Updated 31st July, 2020"
            ghostHeadline={false}
          />
          <div>
            <StyledSubtitle BorderBottom>
              Welcome to Oreville Studio’s privacy policy
            </StyledSubtitle>
            <StyledParagraph>
              Oreville Studios respects your privacy and is committed to
              protecting your personal data. This privacy policy will inform you
              as to how we look after your personal data when you visit our
              website (regardless of where you visit it from), play or interact
              with our games or features developed by or for us, and otherwise
              interact with us and tell you about your privacy rights and how
              the law protects you.
            </StyledParagraph>
            <StyledParagraph>
              Please use the Glossary at the end to understand the meaning of
              some of the terms used in this privacy policy.
            </StyledParagraph>
            <StyledOrderedList>
              <li>
                Important information and who we are
                <StyledItemContent>
                  <StyledSubtitle>
                    Purpose of this privacy policy
                  </StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      This privacy policy aims to give you information on how
                      Oreville Studios collects and processes your personal data
                      through your use of our website (including any data you
                      may provide through our website when you, for example,
                      sign up to any newsletter or contact us) or when you play
                      or interact with our games or features developed by or for
                      us, take part in a competition or other interactions.
                    </StyledParagraph>
                    <StyledParagraph>
                      We do not knowingly collect data relating to children.
                    </StyledParagraph>
                    <StyledParagraph>
                      It is important that you read this privacy policy together
                      with any other privacy policy or fair processing policy we
                      may provide on specific occasions when we are collecting
                      or processing personal data about you so that you are
                      fully aware of how and why we are using your data. This
                      privacy policy supplements other notices and privacy
                      policies and is not intended to override them.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>Controller</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      Oreville Studios Limited is the controller and responsible
                      for your personal data (collectively referred to as
                      "Oreville Studios", "we", "us" or "our" in this privacy
                      policy).
                    </StyledParagraph>
                    <StyledParagraph>
                      We have appointed a data privacy manager who is
                      responsible for overseeing questions in relation to this
                      privacy policy. If you have any questions about this
                      privacy policy, including any requests to exercise your
                      legal rights, please contact the data privacy manager
                      using the details set out below.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>Contact details</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      If you have any questions about this privacy policy or our
                      privacy practices, please contact our data privacy manager
                      in the following ways:
                    </StyledParagraph>
                    <StyledParagraph>
                      <strong>Full name of legal entity:</strong> Oreville
                      Studios Limited
                      <br />
                      <strong>Email address:</strong>{' '}
                      <a href="mailto:data@orevillestudios.com">
                        data@orevillestudios.com
                      </a>
                      <br />
                      <strong>Postal address:</strong> Preston Park House, South
                      Road, Brighton, East Sussex, England, BN1 6SB
                      <br />
                    </StyledParagraph>
                    <StyledParagraph>
                      You have the right to make a complaint at any time to the
                      Information Commissioner's Office (ICO), the UK
                      supervisory authority for data protection issues
                      (www.ico.org.uk). We would, however, appreciate the chance
                      to deal with your concerns before you approach the ICO so
                      please contact us in the first instance.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>
                    Changes to the privacy policy and your duty to inform us of
                    changes
                  </StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We keep our privacy policy under regular review. This
                      version was last updated on 31st July, 2020.
                    </StyledParagraph>
                    <StyledParagraph>
                      It is important that the personal data we hold about you
                      is accurate and current. Please keep us informed if your
                      personal data changes during your relationship with us.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>
                    Third-party links on our website
                  </StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      Our website may include links to third-party websites,
                      plug-ins and applications. Clicking on those links or
                      enabling those connections may allow third parties to
                      collect or share data about you. We do not control these
                      third-party websites and are not responsible for their
                      privacy statements. When you leave our website, we
                      encourage you to read the privacy policy of every website
                      you visit.
                    </StyledParagraph>
                  </StyledBlock>
                </StyledItemContent>
              </li>
              <li>
                The data we collect about you
                <StyledItemContent>
                  <StyledParagraph>
                    Personal data, or personal information, means any
                    information about an individual from which that person can
                    be identified. It does not include data where the identity
                    has been removed (anonymous data).
                  </StyledParagraph>
                  <StyledParagraph>
                    We may collect, use, store and transfer different kinds of
                    personal data about you which we have grouped together as
                    follows (and these words are used throughout our privacy
                    policy):
                  </StyledParagraph>
                  <StyledUnorderedList>
                    <li>
                      <strong>Identity Data</strong> includes first name, last
                      name, username or similar identifier, title, age or date
                      of birth and gender.
                    </li>
                    <li>
                      <strong>Contact Data</strong> includes, email address and
                      telephone numbers and, depending on the use in question, a
                      physical address.
                    </li>
                    <li>
                      <strong>Transaction Data</strong>, if appropriate,
                      includes details of our games and features played on or
                      interacted with.
                    </li>
                    <li>
                      <strong>Technical Data</strong> includes internet protocol
                      (IP) address, browser type and version, time zone setting
                      and country, browser plug-in types and versions, operating
                      system and platform, and other technology on the devices
                      you use to access our website or game features.
                    </li>
                    <li>
                      <strong>Game Data</strong> includes username, feature
                      usage, time played and related statistics, game metrics
                      and similar information.
                    </li>
                    <li>
                      <strong>Profile Data</strong> includes your interests
                      (including, for example, the nature of games played or
                      features used), feedback and survey responses and should
                      we introduce any login functionality on our website,
                      username and password.
                    </li>
                    <li>
                      <strong>Usage Data</strong> includes information about how
                      you use our website, our products, developed content and
                      other services.
                    </li>
                    <li>
                      <strong>Marketing and Communications Data</strong>{' '}
                      includes your preferences in receiving marketing from us
                      and our third parties and your communication preferences.
                    </li>
                  </StyledUnorderedList>
                  <StyledParagraph>
                    We may also collect, use and share Aggregated Data such as
                    statistical or demographic data for any purpose. Aggregated
                    Data could be derived from your personal data but is not
                    considered personal data in law as this data will not
                    directly or indirectly reveal your identity. For example, we
                    may aggregate your Usage Data to calculate the percentage of
                    users accessing a specific website or game feature or Game
                    Data to see how popular particular features may be and how
                    they are used. However, if we combine or connect Aggregated
                    Data with your personal data so that it can directly or
                    indirectly identify you, we treat the combined data as
                    personal data which will be used in accordance with this
                    privacy policy.
                  </StyledParagraph>
                  <StyledParagraph>
                    We do not collect any Special Categories of Personal Data
                    about you (this includes details about your race or
                    ethnicity, religious or philosophical beliefs, sex life,
                    sexual orientation, political opinions, trade union
                    membership, information about your health, and genetic and
                    biometric data). Nor do we collect any information about
                    criminal convictions and offences.
                  </StyledParagraph>
                  <StyledSubtitle>
                    If you fail to provide personal data
                  </StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      Where we need to collect personal data by law, or under
                      the terms of a contract we have with you or where we are
                      trying to assist you in any problem solving, and you fail
                      to provide that data when requested, we may not be able to
                      perform the contract we have with you (if any) or to carry
                      out the other services we may be trying to complete for
                      you. In this case, we may have to cancel or not complete
                      the service we are trying to perform for you. We will
                      notify you if this is the case at the time.
                    </StyledParagraph>
                  </StyledBlock>
                </StyledItemContent>
              </li>
              <li>
                How is your personal data collected?
                <StyledItemContent>
                  <StyledParagraph>
                    We use different methods to collect data from and about you
                    including through:
                  </StyledParagraph>
                  <StyledUnorderedList>
                    <li>
                      <strong>Direct interactions.</strong> You may give us your
                      Identity Data and Contact Data by filling in forms or by
                      corresponding with us by post, phone, email or otherwise.
                      This includes personal data you provide when you:
                      <StyledUnorderedList>
                        <li>enter into a contract with us;</li>
                        <li>
                          create an account on our website, should we introduce
                          such a function;
                        </li>
                        <li>subscribe to any newsletter; </li>
                        <li>request marketing to be sent to you;</li>
                        <li>
                          interact with us regarding any of our games or game
                          features;
                        </li>
                        <li>enter a competition, promotion or survey; or</li>
                        <li>give us feedback of any nature or contact us. </li>
                      </StyledUnorderedList>
                    </li>
                    <li>
                      <strong>Automated technologies or interactions.</strong>{' '}
                      Usage of our game features may provide us with Identity,
                      Game, Transaction and Usage Data. As you interact with our
                      website, we will automatically collect Technical Data
                      about your equipment, browsing actions and patterns. We
                      collect this personal data by using cookies and other
                      similar technologies. Please see our{' '}
                      <Link to="/cookies">cookie policy</Link> for further
                      details.
                    </li>
                    <li>
                      <strong>
                        Third parties or publicly available sources.
                      </strong>{' '}
                      We will receive personal data about you from various third
                      parties as set out below:
                      <ul>
                        <li>
                          Identity and Game Data from Microsoft (based outside
                          the EU) in connection with information it may collect
                          through your use of its services/platform;
                        </li>
                        <li>
                          Technical Data from analytics providers such as Google
                          based outside the EU;
                        </li>
                        <li>
                          Identity and Contact Data from any email marketing
                          platform provider we may engage or via our website
                          platform.
                        </li>
                      </ul>
                    </li>
                  </StyledUnorderedList>
                </StyledItemContent>
              </li>
              <li>
                How we use your personal data
                <StyledItemContent>
                  <StyledParagraph>
                    We will only use your personal data when the law allows us
                    to. Most commonly, we will use your personal data in the
                    following circumstances:
                  </StyledParagraph>
                  <StyledUnorderedList>
                    <li>
                      Where we need to perform the service we would like to
                      perform for you or any contract we are about to enter into
                      or have entered into with you.
                    </li>
                    <li>
                      Where it is necessary for our legitimate interests (or
                      those of a third party) and your interests and fundamental
                      rights do not override those interests.
                    </li>
                    <li>Where we need to comply with a legal obligation.</li>
                  </StyledUnorderedList>
                  <StyledParagraph>
                    The Glossary below sets out more about the types of lawful
                    basis that we will rely on to process your personal data.
                  </StyledParagraph>
                  <StyledParagraph>
                    Generally, we do not rely on consent as a legal basis for
                    processing your personal data although we will get your
                    consent before sending any third party direct marketing
                    communications to you via email or text message. You have
                    the right to withdraw consent to marketing at any time by
                    contacting us.
                  </StyledParagraph>
                  <StyledSubtitle>
                    Purposes for which we will use your personal data
                  </StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We have set out below, in a table format, a description of
                      all the ways we plan to use your personal data, and which
                      of the legal bases we rely on to do so. We have also
                      identified what our legitimate interests are where
                      appropriate.
                    </StyledParagraph>
                    <StyledParagraph>
                      Note that we may process your personal data for more than
                      one lawful ground depending on the specific purpose for
                      which we are using your data. Please contact us if you
                      need details about the specific legal ground we are
                      relying on to process your personal data where more than
                      one ground has been set out in the table below.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledTable>
                    <tbody>
                      <tr>
                        <th>Purpose/Activity</th>
                        <th>Type of data</th>
                        <th>
                          Lawful basis for processing including basis of
                          legitimate interest
                        </th>
                      </tr>
                      <tr>
                        <td>
                          To facilitate the relationship we have with you or to
                          process and deliver any features or other service to
                          you, together with any related activities
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Transaction
                          <br />
                          (d) Game
                          <br />
                          (e) Usage
                          <br />
                          (f) Marketing and Communications
                        </td>
                        <td>
                          (a) Performance of any service for you or a contract
                          we may have with you
                          <br />
                          (b) Necessary for our legitimate interests
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To manage our relationship with you which will
                          include:
                          <br />
                          (a) Notifying you about changes to our terms or
                          privacy policy
                          <br />
                          (b) Asking you to leave a review or take a survey
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Profile
                          <br />
                          (d) Marketing and Communications
                        </td>
                        <td>
                          (a) Performance of any service for you or a contract
                          with you
                          <br />
                          (b) Necessary to comply with a legal obligation
                          <br />
                          (c) Necessary for our legitimate interests (to keep
                          our records updated and to study how customers or
                          others use our products/services)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To enable you to partake in a prize draw, competition
                          or complete a survey or carry out testing of our
                          features
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Profile
                          <br />
                          (d) Usage
                          <br />
                          (e) Marketing and Communications
                          <br />
                          (f) Technical
                          <br />
                          (g) Game
                        </td>
                        <td>
                          (a) Performance of a contract with you
                          <br />
                          (b) Necessary for our legitimate interests (to study
                          how customers use our products/services, to develop
                          them and grow our business)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To administer and protect our business, our games and
                          game features, our website (including troubleshooting,
                          data analysis, testing, system maintenance, support,
                          reporting of intellectual property breaches)
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Technical
                          <br />
                          (d) Game
                          <br />
                          (e) Usage
                        </td>
                        <td>
                          (a) Necessary for our legitimate interests (for
                          running our business, provision of administration and
                          IT services, network security, to prevent intellectual
                          property breaches or other misconduct (whether
                          in-game, about our fatures or otherwise) and in the
                          context of a business reorganisation or group
                          restructuring exercise)
                          <br />
                          (b) Necessary to comply with a legal obligation
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To deliver relevant website and game content and any
                          advertisements to you and measure or understand the
                          effectiveness of any advertising we serve to you
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Profile
                          <br />
                          (d) Usage
                          <br />
                          (e) Marketing and Communications
                          <br />
                          (f) Technical
                        </td>
                        <td>
                          Necessary for our legitimate interests (to study how
                          customers use our games, products, features and
                          services, to develop them, to grow our business and to
                          inform our marketing strategy)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To use data analytics to improve our games and
                          features, our website, products/services, marketing,
                          customer relationships and experiences
                        </td>
                        <td>
                          (a) Technical
                          <br />
                          (b) Game
                          <br />
                          (c) Usage
                        </td>
                        <td>
                          Necessary for our legitimate interests (to define
                          types of customers for our games, features, products
                          and services, to keep our website and game features
                          updated and relevant, to develop our business and to
                          inform our marketing strategy)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To make suggestions and recommendations to you about
                          games, features, products or services or provide you
                          with news that may be of interest to you through
                          newsletters or similar communications
                        </td>
                        <td>
                          (a) Identity
                          <br />
                          (b) Contact
                          <br />
                          (c) Technical
                          <br />
                          (d) Game
                          <br />
                          (e) Usage
                          <br />
                          (f) Profile
                          <br />
                          (g) Marketing and Communications
                        </td>
                        <td>
                          Necessary for our legitimate interests (to develop our
                          products/services and grow our business)
                        </td>
                      </tr>
                    </tbody>
                  </StyledTable>
                  <StyledSubtitle>Marketing</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We strive to provide you with choices regarding certain
                      personal data uses, particularly around marketing and
                      advertising. We provide the following personal data
                      control mechanisms:
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>Promotional offers from us</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We may use your Identity, Contact, Technical, Game, Usage
                      and Profile Data to form a view on what we think you may
                      want or need, or what may be of interest to you. This is
                      how we decide which services, offers and features may be
                      relevant for or of interest to you (we call this
                      marketing).
                    </StyledParagraph>
                    <StyledParagraph>
                      You will receive marketing communications from us if you
                      have requested information from us or purchased features
                      or other products or services from us and provided your
                      contact details as part of that process and you have not
                      opted out of receiving that marketing.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>Third-party marketing</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We will get your express opt-in consent before we share
                      your personal data with any third party for marketing
                      purposes.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>Opting out</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      You can ask us or third parties to stop sending you
                      marketing messages at any time by following the opt-out
                      links on any marketing message sent to you.
                    </StyledParagraph>
                    <StyledParagraph>
                      Where you opt out of receiving these marketing messages,
                      this will not apply to personal data provided to us as a
                      result of a product, feature or service experience that
                      you may have requested or that we may have provided or
                      other such interactions.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>Cookies</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      You can set your browser to refuse all or some browser
                      cookies, or to alert you when websites set or access
                      cookies. If you disable or refuse cookies, please note
                      that some parts of our website may become inaccessible or
                      not function properly. For more information about the
                      cookies we use, please see our{' '}
                      <Link to="/cookies">cookie policy</Link>.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>Change of purpose</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We will only use your personal data for the purposes for
                      which we collected it, unless we reasonably consider that
                      we need to use it for another reason and that reason is
                      compatible with the original purpose. If you wish to get
                      an explanation as to how the processing for the new
                      purpose is compatible with the original purpose, please
                      contact us.
                    </StyledParagraph>
                    <StyledParagraph>
                      If we need to use your personal data for an unrelated
                      purpose, we will notify you and we will explain the legal
                      basis which allows us to do so.
                    </StyledParagraph>
                    <StyledParagraph>
                      Please note that we may process your personal data without
                      your knowledge or consent, in compliance with the above
                      rules, where this is required or permitted by law.
                    </StyledParagraph>
                  </StyledBlock>
                </StyledItemContent>
              </li>
              <li>
                Disclosures of your personal data
                <StyledItemContent>
                  <StyledParagraph>
                    We may share your personal data with the parties set out
                    below for the purposes set out in the table above.
                  </StyledParagraph>
                  <StyledUnorderedList>
                    <li>External Third Parties as set out in the Glossary.</li>
                    <li>
                      Specific third parties such as any marketing
                      communications provider that we may appoint from time to
                      time.
                    </li>
                    <li>
                      Third parties to whom we may choose to sell, transfer or
                      merge parts of our business or our assets. Alternatively,
                      we may seek to acquire other businesses or merge with
                      them. If a change happens to our business, then the new
                      owners may use your personal data in the same way as set
                      out in this privacy policy.
                    </li>
                  </StyledUnorderedList>
                  <StyledParagraph>
                    We require all third parties to respect the security of your
                    personal data and to treat it in accordance with the law. We
                    do not allow our third-party service providers to use your
                    personal data for their own purposes and only permit them to
                    process your personal data for specified purposes and in
                    accordance with our instructions.
                  </StyledParagraph>
                </StyledItemContent>
              </li>
              <li>
                International transfers
                <StyledItemContent>
                  <StyledParagraph>
                    Some of our external third parties are based outside the EEA
                    so their processing of your personal data will involve a
                    transfer of data outside the EEA.
                  </StyledParagraph>
                  <StyledParagraph>
                    Whenever we transfer your personal data out of the EEA, we
                    ensure a similar degree of protection is afforded to it by
                    ensuring at least one of the following safeguards is
                    implemented:
                  </StyledParagraph>
                  <StyledUnorderedList>
                    <li>
                      We will only transfer your personal data to countries that
                      have been deemed to provide an adequate level of
                      protection for personal data by the European Commission.
                    </li>
                    <li>
                      Where we use certain service providers, we may use
                      specific contracts approved by the European Commission
                      which give personal data the same protection it has in
                      Europe.
                    </li>
                    <li>
                      Where we use providers based in the US, we may transfer
                      data to them if they are part of the Privacy Shield scheme
                      which requires them to provide similar protection to
                      personal data shared between Europe and the US. Any
                      information received from or sent to Microsoft will be in
                      accordance with the EU/US Privacy Shield scheme.
                    </li>
                  </StyledUnorderedList>
                </StyledItemContent>
              </li>
              <li>
                Data security
                <StyledItemContent>
                  <StyledParagraph>
                    We have put in place appropriate security measures to
                    prevent your personal data from being accidentally lost,
                    used or accessed in an unauthorised way, altered or
                    disclosed. In addition, we limit access to your personal
                    data to those employees, agents, contractors and other third
                    parties who have a business need to know. They will only
                    process your personal data on our instructions and they are
                    subject to a duty of confidentiality.
                  </StyledParagraph>
                  <StyledParagraph>
                    We have put in place procedures to deal with any suspected
                    personal data breach and will notify you and any applicable
                    regulator of a breach where we are legally required to do
                    so.
                  </StyledParagraph>
                </StyledItemContent>
              </li>
              <li>
                Data retention
                <StyledItemContent>
                  <StyledSubtitle>
                    How long will you use my personal data for?
                  </StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We will only retain your personal data for as long as
                      reasonably necessary to fulfil the purposes we collected
                      it for, including for the purposes of satisfying any
                      legal, regulatory, tax, accounting or reporting
                      requirements. We may retain your personal data for a
                      longer period in the event of a complaint or if we
                      reasonably believe there is a prospect of litigation in
                      respect to our relationship with you.
                    </StyledParagraph>
                    <StyledParagraph>
                      To determine the appropriate retention period for personal
                      data, we consider the amount, nature and sensitivity of
                      the personal data, the potential risk of harm from
                      unauthorised use or disclosure of your personal data, the
                      purposes for which we process your personal data and
                      whether we can achieve those purposes through other means,
                      and the applicable legal, regulatory, tax, accounting or
                      other requirements.
                    </StyledParagraph>
                    <StyledParagraph>
                      In some circumstances you can ask us to delete your data:
                      see under “Your Legal Rights” below for further
                      information.
                    </StyledParagraph>
                    <StyledParagraph>
                      In some circumstances we will anonymise your personal data
                      (so that it can no longer be associated with you) for
                      research or statistical purposes, in which case we may use
                      this information indefinitely without further notice to
                      you.
                    </StyledParagraph>
                  </StyledBlock>
                </StyledItemContent>
              </li>
              <li>
                Your legal rights
                <StyledItemContent>
                  <StyledParagraph>
                    Under certain circumstances, you have rights under data
                    protection laws in relation to your personal data. These are
                    set out under “Your Legal Rights” below.
                  </StyledParagraph>
                  <StyledParagraph>
                    If you wish to exercise any of the rights set out above,
                    please contact us.
                  </StyledParagraph>
                  <StyledSubtitle>No fee usually required</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      You will not have to pay a fee to access your personal
                      data (or to exercise any of the other rights). However, we
                      may charge a reasonable fee if your request is clearly
                      unfounded, repetitive or excessive. Alternatively, we
                      could refuse to comply with your request in these
                      circumstances.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>What we may need from you</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We may need to request specific information from you to
                      help us confirm your identity and ensure your right to
                      access your personal data (or to exercise any of your
                      other rights). This is a security measure to ensure that
                      personal data is not disclosed to any person who has no
                      right to receive it. We may also contact you to ask you
                      for further information in relation to your request to
                      speed up our response.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>Time limit to respond</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      We try to respond to all legitimate requests within one
                      month. Occasionally it could take us longer than a month
                      if your request is particularly complex or you have made a
                      number of requests. In this case, we will notify you and
                      keep you updated.
                    </StyledParagraph>
                  </StyledBlock>
                </StyledItemContent>
              </li>
              <li>
                Glossary
                <StyledItemContent>
                  <StyledSubtitle>LAWFUL BASIS</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      <strong>Legitimate Interest</strong> means the interest of
                      our business in conducting and managing our business to
                      enable us to give you the best service or products and the
                      best and most secure experience. We make sure we consider
                      and balance any potential impact on you (both positive and
                      negative) and your rights before we process your personal
                      data for our legitimate interests. We do not use your
                      personal data for activities where our interests are
                      overridden by the impact on you (unless we have your
                      consent or are otherwise required or permitted to by law).
                      You can obtain further information about how we assess our
                      legitimate interests against any potential impact on you
                      in respect of specific activities by contacting us.
                    </StyledParagraph>
                    <StyledParagraph>
                      <strong>Performance of Contract</strong> means processing
                      your data where it is necessary for the performance of a
                      contract to which you are a party or to take steps at your
                      request before entering into such a contract.
                    </StyledParagraph>
                    <StyledParagraph>
                      <strong>Comply with a legal obligation</strong> means
                      processing your personal data where it is necessary for
                      compliance with a legal obligation that we are subject to.
                    </StyledParagraph>
                  </StyledBlock>
                  <StyledSubtitle>THIRD PARTIES</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>
                      <strong>External Third Parties</strong>
                    </StyledParagraph>
                    <StyledUnorderedList>
                      <li>
                        Service providers acting as processors who provide IT,
                        game-related or other system administration services,
                        whether in conjunction with our games or marketing or
                        otherwise.
                      </li>
                      <li>
                        Microsoft, as owners of Minecraft, where such may be
                        necessary in order to investigate any issue which may
                        arise in relation to our products or the use of our
                        products.
                      </li>
                      <li>
                        If appropriate, any of our network of feature testers.
                      </li>
                      <li>
                        Professional advisers acting as processors or joint
                        controllers including lawyers, accountants and insurers
                        based most likely in the United Kingdom (but also in any
                        other country that may be relevant to the issue in
                        question) who provide consultancy, legal, insurance and
                        accounting services.
                      </li>
                      <li>
                        HM Revenue & Customs, regulators and other appropriate
                        authorities acting as processors or joint controllers
                        based in the United Kingdom who require reporting of
                        processing activities in certain circumstances.
                      </li>
                    </StyledUnorderedList>
                  </StyledBlock>
                  <StyledSubtitle>YOUR LEGAL RIGHTS</StyledSubtitle>
                  <StyledBlock>
                    <StyledParagraph>You have the right to:</StyledParagraph>
                    <StyledParagraph>
                      <strong>Request access</strong> to your personal data
                      (commonly known as a "data subject access request"). This
                      enables you to receive a copy of the personal data we hold
                      about you and to check that we are lawfully processing it.
                    </StyledParagraph>
                    <StyledParagraph>
                      <strong>Request correction</strong> of the personal data
                      that we hold about you. This enables you to have any
                      incomplete or inaccurate data we hold about you corrected,
                      though we may need to verify the accuracy of the new data
                      you provide to us.
                    </StyledParagraph>
                    <StyledParagraph>
                      <strong>Request erasure</strong> of your personal data.
                      This enables you to ask us to delete or remove personal
                      data where there is no good reason for us continuing to
                      process it. You also have the right to ask us to delete or
                      remove your personal data where you have successfully
                      exercised your right to object to processing (see below),
                      where we may have processed your information unlawfully or
                      where we are required to erase your personal data to
                      comply with local law. Note, however, that we may not
                      always be able to comply with your request of erasure for
                      specific legal reasons which will be notified to you, if
                      applicable, at the time of your request.
                    </StyledParagraph>
                    <StyledParagraph>
                      <strong>Object to processing</strong> of your personal
                      data where we are relying on a legitimate interest (or
                      those of a third party) and there is something about your
                      particular situation which makes you want to object to
                      processing on this ground as you feel it impacts on your
                      fundamental rights and freedoms. You also have the right
                      to object where we are processing your personal data for
                      direct marketing purposes. In some cases, we may
                      demonstrate that we have compelling legitimate grounds to
                      process your information which override your rights and
                      freedoms.
                    </StyledParagraph>
                    <StyledParagraph>
                      <strong>Request restriction of processing</strong> of your
                      personal data. This enables you to ask us to suspend the
                      processing of your personal data in the following
                      scenarios:
                    </StyledParagraph>
                    <StyledUnorderedList>
                      <li>If you want us to establish the data's accuracy.</li>
                      <li>
                        Where our use of the data is unlawful but you do not
                        want us to erase it.
                      </li>
                      <li>
                        Where you need us to hold the data even if we no longer
                        require it as you need it to establish, exercise or
                        defend legal claims.
                      </li>
                      <li>
                        You have objected to our use of your data but we need to
                        verify whether we have overriding legitimate grounds to
                        use it.
                      </li>
                    </StyledUnorderedList>
                    <StyledParagraph>
                      <strong>Request the transfer</strong> of your personal
                      data to you or to a third party. We will provide to you,
                      or a third party you have chosen, your personal data in a
                      structured, commonly used, machine-readable format. Note
                      that this right only applies to automated information
                      which you initially provided consent for us to use or
                      where we used the information to perform a contract with
                      you.
                    </StyledParagraph>
                    <StyledParagraph>
                      <strong>Withdraw consent at any time</strong> where we are
                      relying on consent to process your personal data. However,
                      this will not affect the lawfulness of any processing
                      carried out before you withdraw your consent. If you
                      withdraw your consent, we may not be able to provide
                      certain products or services to you. We will advise you if
                      this is the case at the time you withdraw your consent.
                    </StyledParagraph>
                  </StyledBlock>
                </StyledItemContent>
              </li>
            </StyledOrderedList>
          </div>
        </StyledContainer>
      </StyledBackground>
    </Fragment>
  )
}

export default Privacy

const StyledBackground = styled(Background)``
const StyledContainer = styled(Container)`
  display: grid;
  grid-row-gap: 8em;
`
const StyledOrderedList = styled.ol`
  font-size: var(--body-font-size-6);
  margin: 0;
  padding: 4em 0 0 0;

  & > li:not(:last-child) {
    margin: 0 0 3.5em;
  }
`
const StyledUnorderedList = styled.ul`
  list-style: disc;
  margin: 1em 0;

  & > li {
    margin: 0 0 0.8em;
    padding: 0 0 0 0.4em;
    line-height: 1.5em;
  }
`
const StyledItemContent = styled.div`
  font-size: var(--body-font-size-1);
`

interface IStyledSubtitle {
  BorderBottom?: boolean
}

const StyledSubtitle = styled.h3<IStyledSubtitle>`
  ${({ BorderBottom }) =>
    BorderBottom &&
    css`
      display: block;
      padding: 0 0 1em;
      border-bottom: 1px solid var(--secondary-font-color-1);
    `}
`
const StyledParagraph = styled.p`
  color: var(--secondary-font-color-1);
`
const StyledBlock = styled.div`
  border-left: 4px solid var(--secondary-font-color-1);
  padding-left: 1em;
`
const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  & tr {
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--secondary-font-color-1);
    }
  }

  & th,
  & td {
    vertical-align: top;
    padding: 2em;
    line-height: 1.5em;

    &:first-of-type {
      padding-left: 0;
    }

    &:not(:last-of-type) {
      border-right: 1px solid var(--secondary-font-color-1);
    }
  }
`
