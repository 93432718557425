interface IStringDict {
  [index: string]: string
}

const Size: IStringDict = {
  small: '375px',
  medium: '600px',
  large: '1100px',
}

const MediaQuery = Object.keys(Size).reduce(
  (accumulator: IStringDict, currentValue) => {
    accumulator[currentValue] = `screen and (min-width: ${Size[currentValue]})`
    return accumulator
  },
  {}
)

export default MediaQuery
