import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import Heading from '../components/Heading'
import HomeHeader from '../layouts/headers/HomeHeader'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'

const Cookies: React.FC = () => {
  return (
    <Fragment>
      <HomeHeader />
      <StyledBackground>
        <StyledContainer>
          <Heading
            headline="Cookies"
            subheadline="How does Oreville use cookies?"
            ghostHeadline={false}
          />
          <div>
            <StyledSubtitle BorderBottom>Cookie Policy</StyledSubtitle>
            <StyledParagraph>
              Our orevillestudios.com website uses cookies to distinguish you
              from other users of our website. This helps us to provide you with
              a good experience when you browse our website and also allows us
              to improve our site.
            </StyledParagraph>
            <StyledParagraph>
              A cookie is a small file of letters and numbers that we store on
              your browser or the hard drive of your computer if you agree.
              Cookies contain information that is transferred to your computer's
              hard drive.
            </StyledParagraph>
            <StyledParagraph>We use the following cookies:</StyledParagraph>
            <StyledUnorderedList>
              <li>
                <strong>Identity Data</strong> includes first name, last name,
                username or similar identifier, title, age or date of birth and
                gender.
              </li>
              <li>
                <strong>Contact Data</strong> includes, email address and
                telephone numbers and, depending on the use in question, a
                physical address.
              </li>
            </StyledUnorderedList>
            <StyledParagraph>
              You can find more information about the individual cookies we use
              and the purposes for which we use them in the table below:
            </StyledParagraph>
            <StyledTable>
              <tbody>
                <tr>
                  <th>Cookie Name</th>
                  <th>Purpose</th>
                  <th>Duration</th>
                  <th>More information</th>
                </tr>
                <tr>
                  <td>_cfduid</td>
                  <td>
                    <p>
                      Our website uses the Cloudflare service and the _cfduid
                      cookie helps Cloudflare detect malicious visitors to our
                      website and minimizes blocking legitimate users. It is
                      placed to identify individual visitors behind any shared
                      IP address and applies security settings on a per-client
                      basis. It is necessary for supporting Cloudflare's
                      security features.
                    </p>
                    <p>The _cfduid cookie does not:</p>
                    <StyledUnorderedList>
                      <li>allow for cross-site tracking; or</li>
                      <li>follow users from site to site.</li>
                    </StyledUnorderedList>
                  </td>
                  <td>One month</td>
                  <td>This is a strictly necessary cookie</td>
                </tr>
                <tr>
                  <td>_ga</td>
                  <td>
                    This is an analytical cookie provided by Google and is used
                    to distinguish visitors from one another. It does not store
                    any personally identifiable information.
                  </td>
                  <td>2 years</td>
                  <td>This is an analytical cookie</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>
                    This is an analytical cookie provided by Google and is used
                    to distinguish visitors from one another. It does not store
                    any personally identifiable information.
                  </td>
                  <td>24 hours</td>
                  <td>This is an analytical cookie</td>
                </tr>
              </tbody>
            </StyledTable>
            <StyledSubtitle>YouTube</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                We have an embedded YouTube video within our site and it uses
                YouTube's privacy-enhanced mode. This mode may set cookies on
                your device once you click to play the YouTube video, but
                YouTube will not store personally-identifiable cookie
                information for playbacks of embedded videos using the
                privacy-enhanced mode.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>Blocking cookies</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                You can block cookies by activating the setting on your browser
                that allows you to refuse the setting of all or some cookies.
                However, if you use your browser settings to block all cookies
                (including essential cookies) you may not be able to access all
                or parts of our website and parts may not function as intended.
              </StyledParagraph>
            </StyledBlock>
          </div>
        </StyledContainer>
      </StyledBackground>
    </Fragment>
  )
}

export default Cookies

const StyledBackground = styled(Background)``
const StyledContainer = styled(Container)`
  display: grid;
  grid-row-gap: 8em;
`
const StyledUnorderedList = styled.ul`
  list-style: disc;
  margin: 1em 0;

  & > li {
    margin: 0 0 0.8em;
    padding: 0 0 0 0.4em;
    line-height: 1.5em;
  }
`

interface IStyledSubtitle {
  BorderBottom?: boolean
}

const StyledSubtitle = styled.h3<IStyledSubtitle>`
  ${({ BorderBottom }) =>
    BorderBottom &&
    css`
      display: block;
      padding: 0 0 1em;
      border-bottom: 1px solid var(--secondary-font-color-1);
    `}
`
const StyledParagraph = styled.p`
  color: var(--secondary-font-color-1);
`
const StyledBlock = styled.div`
  border-left: 4px solid var(--secondary-font-color-1);
  padding-left: 1em;
`
const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  & tr {
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--secondary-font-color-1);
    }
  }

  & th,
  & td {
    vertical-align: top;
    padding: 2em;
    line-height: 1.5em;

    &:first-of-type {
      padding-left: 0;
    }

    &:not(:last-of-type) {
      border-right: 1px solid var(--secondary-font-color-1);
    }
  }
`
