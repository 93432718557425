import React, { Fragment } from 'react'
import styled from 'styled-components'
import Heading from '../components/Heading'
import StoreHeader from '../layouts/headers/StoreHeader'
import { Container } from '../styles/Container'

const Store: React.FC = () => {
  return (
    <Fragment>
      <StyledWrapper>
        <StoreHeader />
        <StyledBackground>
          <StyledContainer>
            <Heading
              headline="Coming Soon!"
              ghostHeadline={false}
              subheadline="Sit tight, we're almost done."
            />
          </StyledContainer>
        </StyledBackground>
      </StyledWrapper>
    </Fragment>
  )
}

export default Store

const StyledWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const StyledBackground = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledContainer = styled(Container)``
