import React from 'react'
import styled from 'styled-components'
import Logo from '../../components/Logo'
import { Background } from '../../styles/Background'
import { Container } from '../../styles/Container'
import MediaQuery from '../../utils/MediaQuery'

const GlobalHeader: React.FC = ({ children }) => {
  return (
    <StyledBackground as="header">
      <StyledContainer>
        <StyledWrapper>
          <StyledLogo />
          {children}
        </StyledWrapper>
      </StyledContainer>
    </StyledBackground>
  )
}

export default GlobalHeader

const StyledBackground = styled(Background)`
  padding: 2em 0;

  @media ${MediaQuery.large} {
    padding: 3em 0;
  }
`
const StyledContainer = styled(Container)`
  & {
    padding: 0;
  }
`
const StyledWrapper = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'logo . nav';
  align-items: center;

  @media ${MediaQuery.large} {
    grid-template-areas: 'logo nav cta';
  }
`
const StyledLogo = styled(Logo)`
  grid-area: logo;
`
