import React from 'react'
import styled, { css } from 'styled-components'
import MediaQuery from '../utils/MediaQuery'

interface IHeadingProps extends IStyledProps {
  className?: string
  subheadline: string
  body?: any
}

const Heading: React.FC<IHeadingProps> = ({
  headline,
  ghostHeadline = true,
  subheadline,
  body,
  children,
  className,
  ...props
}) => {
  return (
    <StyledWrapper className={className}>
      <StyledHeading
        headline={headline}
        ghostHeadline={ghostHeadline}
        {...props}
      >
        {headline}
        {children}
      </StyledHeading>
      <StyledSubheadline>{subheadline}</StyledSubheadline>
      {body && <StyledBody>{body}</StyledBody>}
    </StyledWrapper>
  )
}

export default Heading

interface IStyledProps {
  headline: string
  ghostHeadline?: boolean
}

const StyledWrapper = styled.header`
  display: flex;
  flex-direction: column;

  @media ${MediaQuery.medium} {
    align-items: center;
    font-size: var(--heading-font-size-1);
    text-align: center;
  }
`
const StyledHeading = styled.h1<IStyledProps>`
  width: 100%;
  // margin: 0;
  // font-size: 48px;
  // font-weight: 700;
  text-transform: capitalize;
  position: relative;

  margin: 0 0 15px;
  font-size: 25px;
  font-weight: 600;

  @media ${MediaQuery.medium} {
    font-size: var(--heading-font-size-1);

    margin: 0;
    font-weight: 700;
  }

  ${({ ghostHeadline, headline }) =>
    ghostHeadline &&
    css`
      @media ${MediaQuery.medium} {
        &::before {
          content: '${headline}';
          position: absolute;
          left: 0;
          right: 0;
          top: -50%;
          text-transform: uppercase;
          font-size: calc(var(--heading-font-size-1) + 1.1rem);
          font-weight: 900;
          letter-spacing: 0.05em;
          // opacity: 0.1;

          background-image: linear-gradient(
            rgba(255, 255, 255, 0.1),
            transparent
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }
    `}
`
const StyledSubheadline = styled.h2`
  max-width: 20em;
  margin: 0;
  // font-size: var(--subheading-font-size-1);
  // font-weight: 300;

  font-size: 48px;
  font-weight: 700;

  @media ${MediaQuery.medium} {
    font-size: var(--subheading-font-size-1);
    font-weight: 300;
  }
`
const StyledBody = styled.p`
  font-size: 21px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.008em;
  color: var(--secondary-font-color-1);
  margin: 32px 0 0;

  @media ${MediaQuery.medium} {
    max-width: 80%;
    margin: 2em 0 0;

    font-size: var(--body-font-size-6);
    line-height: 1.2;
  }
`
