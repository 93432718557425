import React from 'react'
import Navigation from '../../components/Navigation'
import GlobalHeader from './GlobalHeader'

const HomeHeader: React.FC = () => {
  return (
    <GlobalHeader>
      <Navigation
        links={[
          { href: '/#creations', name: 'Creations' },
          { href: '/#what-we-do', name: 'What We Do' },
          { href: '/#contact', name: 'Contact' },
        ]}
      />
    </GlobalHeader>
  )
}

export default HomeHeader
