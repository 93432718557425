import { createGlobalStyle } from 'styled-components'
import MediaQuery from '../utils/MediaQuery'

const GlobalStyles = createGlobalStyle`
  :root {
    --primary-font-1: 'Montserrat', sans-serif;
    --secondary-font-1: 'Inter', sans-serif;

    --heading-font-size-1: 3.875rem; // 62px
    --heading-font-size-2: 3rem; // 48px
    --subheading-font-size-1: 2.125rem; // 34px

    --body-font-size-0: 0.875em; // 14px
    --body-font-size-1: 1rem; // 16px
    --body-font-size-2: 1.0625rem; // 17px
    --body-font-size-3: 1.1875rem; // 19px
    --body-font-size-4: 1.25rem; // 20px
    --body-font-size-5: 1.375rem; // 22px
    --body-font-size-6: 1.563rem; // 25px

    --primary-font-color-1: #fff;
    --secondary-font-color-1: #7d82ac;

    // font-size: 70%;
  }

  @media ${MediaQuery.medium} {
    :root {
      // font-size: 85%;
    }
  }

  @media ${MediaQuery.large} {
    :root {
      // font-size: 100%;
    }
  }

  html, body, #root {
    min-height: 100%;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    // scroll-padding: 250px 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    color: var(--primary-font-color-1);
    background: radial-gradient(rgba(255, 255, 255, .05) 1px, transparent 1px) center, #1c0a2e;
    background-size: 15px 15px;
    font-family: var(--secondary-font-1);
    font-size: var(--body-font-size-1);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    display: inline-block;
    color: #41a3f2;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  p {
    line-height: 1.5;
  }

  iframe {
    display: block;
  }

  img {
    display: block;
    max-width: 100%;
  }

  strong {
    font-weight: 500;
    color: #fff;
  }
`

export default GlobalStyles
