import React, { Fragment } from 'react'
import styled from 'styled-components'
import HomeHeader from '../layouts/headers/HomeHeader'
import { Container } from '../styles/Container'

const NotFound: React.FC = () => {
  return (
    <Fragment>
      <HomeHeader />
      <StyledBackground>
        <StyledContainer>
          <h1>404 Page not found!</h1>
        </StyledContainer>
      </StyledBackground>
    </Fragment>
  )
}

export default NotFound

const StyledBackground = styled.div``
const StyledContainer = styled(Container)`
  text-align: center;
  min-height: 100vh;
`
