import React, { Fragment } from 'react'
import styled from 'styled-components'
import Heading from '../components/Heading'
// import ContactFormContainer from '../containers/ContactFormContainer'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'
import MediaQuery from '../utils/MediaQuery'

const Contact: React.FC = () => {
  return (
    <StyledBackground id="contact">
      <StyledContainer>
        <Heading
          headline="Contact"
          subheadline="We'd love to hear from you."
          body={
            <Fragment>
              <strong>Hit bedrock? Need help?</strong> Have a question, or just
              want to drop us some feedback? Get in touch! We're more than happy
              to assist where we can and will do our best to get back to you.{' '}
              <a href="mailto:contact@orevillestudios.com">
                contact@orevillestudios.com
              </a>{' '}
              Alternatively, you can tweet us{' '}
              <a
                href="//twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=@OrevilleStudios"
                target="_blank"
                rel="noopener noreferrer"
              >
                @OrevilleStudios
              </a>
              , or join the conversation on our{' '}
              <StyledSpan>
                <a
                  href="//discord.gg/dsNCAnM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discord
                </a>
                .
              </StyledSpan>
            </Fragment>
          }
        />
        {/* <StyledContactFormContainer /> */}
      </StyledContainer>
    </StyledBackground>
  )
}

export default Contact

const StyledBackground = styled(Background)``
const StyledContainer = styled(Container)`
  grid-row-gap: 4em;

  @media ${MediaQuery.medium} {
    grid-row-gap: 8em;
  }
`
// const StyledContactFormContainer = styled(ContactFormContainer)`
//   @media ${MediaQuery.medium} {
//     max-width: 80%;
//   }
// `

const StyledSpan = styled.span`
  display: inline-block;
`
