import React from 'react'
import styled from 'styled-components'
import MediaQuery from '../utils/MediaQuery'

interface IMediaObjectProps {
  src: string
  alt: string
  heading: string
  body: string
}

export default function MediaObject({
  src,
  alt,
  heading,
  body,
}: IMediaObjectProps) {
  return (
    <StyledWrapper>
      <StyledImage src={src} alt={alt} />
      <StyledBody>
        <StyledHeading>{heading}</StyledHeading>
        <StyledParagraph>{body}</StyledParagraph>
      </StyledBody>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'image'
    'body';
  grid-gap: 2em;

  @media ${MediaQuery.medium} {
    grid-template-columns: minmax(20%, 30%) auto;
    grid-template-areas: 'image body';
    text-align: left;
  }
`
const StyledImage = styled.img`
  grid-area: image;
  place-self: center;
  display: block;
  width: 55%;
`
const StyledBody = styled.div`
  grid-area: body;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: var(--body-font-size-1);
`
const StyledHeading = styled.h1`
  grid-area: title;
  margin: 0 0 0.4em;
  font-size: var(--body-font-size-4);
  font-weight: 600;
  text-transform: capitalize;
`
const StyledParagraph = styled.p`
  grid-area: body;
  margin: 0;
  color: var(--secondary-font-color-1);
  font-size: 18px;

  @media ${MediaQuery.medium} {
    font-size: var(--body-font-size-3);
  }
`
