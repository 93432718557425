// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Button } from '../components/FormElements'
import Heading from '../components/Heading'
// import CarouselContainer from '../containers/CarouselContainer'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'

export default function Creations() {
  return (
    <StyledBackground id="creations">
      <StyledContainer>
        <Heading
          headline="Our Creations"
          subheadline="Browse. Explore. Download."
          body={
            <Fragment>
              From stopping the pesky Pigs' grand plans in{' '}
              <strong>Minecraft x Angry Birds</strong>, to stepping into the
              boots of your favourite Supers in{' '}
              <strong>Minecraft x The Incredibles</strong> &mdash; we've been
              fortunate to work with renowned global brands to deliver
              unforgettable experiences! With a diverse variety of content being
              added to our growing catalogue, be sure to regularly check in and
              discover what's new!
            </Fragment>
          }
        />
        <Button
          as="a"
          href="//www.minecraft.net/en-us/marketplace/creator?name=oreville%20studios"
          target="_blank"
        >
          Explore
          {/* <FontAwesomeIcon icon="magnifying-glass" /> */}
        </Button>
        {/* <CarouselContainer /> */}
      </StyledContainer>
    </StyledBackground>
  )
}

const StyledBackground = styled(Background)`
  background: #0e051735;
`
const StyledContainer = styled(Container)`
  grid-row-gap: 4em;

  > * {
    &:last-child {
      grid-column: full;
    }
  }
`
