import React from 'react'
import Clients from '../layouts/Clients'
import Contact from '../layouts/Contact'
import Creations from '../layouts/Creations'
import Hero from '../layouts/Hero'
// import Newsletter from '../layouts/Newsletter'
import WhatWeDo from '../layouts/WhatWeDo'
import HomeHeader from '../layouts/headers/HomeHeader'

export default function Home() {
  return (
    <>
      <HomeHeader />
      <main>
        <Hero />
        <Creations />
        <WhatWeDo />
        <Clients />
        {/* <Newsletter /> */}
        <Contact />
      </main>
    </>
  )
}
