import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { css } from 'styled-components'

interface ILinkProps {
  icon: IconProp
  href: string
  target?: string
  rel?: string
  ariaLabel: string
}

interface ISocialProps extends IStyledProps {
  className?: string
  icons: Array<ILinkProps>
}

export default function Social({ className, icons, ...props }: ISocialProps) {
  return (
    <StyledWrapper aria-label="Social links" className={className} {...props}>
      {icons.map(
        (
          {
            icon,
            href,
            target = '_blank',
            rel = 'noopener noreferrer',
            ariaLabel,
          },
          index
        ) => (
          <StyledLink
            href={href}
            target={target}
            rel={rel}
            key={index}
            aria-label={ariaLabel}
            {...props}
          >
            <FontAwesomeIcon icon={icon} />
          </StyledLink>
        )
      )}
    </StyledWrapper>
  )
}

interface IStyledProps {
  footer?: boolean
}
const StyledWrapper = styled.nav<IStyledProps>`
  display: flex;
  gap: 1.02rem;
  flex-wrap: wrap;

  ${({ footer }) =>
    footer &&
    css`
      gap: 1.3rem;
    `}
`
const StyledLink = styled.a<IStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  text-decoration: none;
  cursor: pointer;
  font-size: 1.6rem;
  color: #fff;
  transition: all 0.4s;
  will-change: transform;
  border-radius: 14px;
  width: 2.4em;
  height: 2.4em;
  box-shadow: 0 7px 50px 0 rgba(0, 0, 0, 0.3);

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    transform: scale(1.2);
    background: #c261ee;
  }

  ${({ footer }) =>
    footer &&
    css`
      background: unset;
      color: rgba(255, 255, 255, 0.5);
      width: auto;
      height: auto;

      &:hover,
      &:focus-visible {
        transform: unset;
        background: unset;
        color: #fff;
      }
    `}
`
