import React, { useCallback, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

interface ITypewriter {
  typingDelay: number
  erasingDelay: number
  newTextDelay: number
  textArray: Array<string>
  loop?: boolean
}

interface IDelayText {
  text: string
  delay: number
}

const Typewriter: React.FC<ITypewriter> = ({
  typingDelay,
  erasingDelay,
  newTextDelay,
  textArray,
  loop = false,
}) => {
  const [states, setStates] = useState<Array<IDelayText>>([])
  const [stateIndex, setStateIndex] = useState(0)
  const [textContent, setTextContent] = useState('')
  const [typing, setTyping] = useState(false)
  const [stopped, setStopped] = useState(false)

  // compose all text states and their delays and store them in an array
  // i.e. "" => "h" => "ha" => "har" => etc, etc
  const init = useCallback(() => {
    // create empty array
    const allStates: Array<IDelayText> = []
    // iterate over all words
    textArray.forEach((word) => {
      // push "h", "ha", etc
      for (let i = 1; i <= word.length; i++)
        allStates.push({
          text: word.substr(0, i),
          delay: typingDelay,
        })
      // push "hard", "har", "ha", etc.
      for (let i = word.length - 1; i >= 0; i--)
        allStates.push({
          text: word.substr(0, i),
          delay: i === word.length - 1 ? newTextDelay : erasingDelay,
        })
      // push blank text
      allStates.push({ text: '', delay: typingDelay })
    })
    setStates(allStates)
  }, [erasingDelay, typingDelay, newTextDelay, textArray])

  // call init, exactly once
  useEffect(() => {
    init()
  }, [init])

  // in the beginning, and if stateIndex has changes, set timeout
  // to schedule next text change
  useEffect(() => {
    // array not ready yet
    if (states.length === 0) return
    const delay = states[stateIndex].delay

    // calculate next states index
    const nextIndex = (stateIndex + 1) % states.length

    // if final word is fully displayed, stop if loop is false
    const lastWordLength = textArray.slice(-1)[0].length
    if (nextIndex === states.length - lastWordLength && !loop) {
      setStopped(true)
      return
    }

    // schedule next state
    const timeout = setTimeout(() => {
      const nextDelay = states[nextIndex].delay
      setTyping(nextDelay === typingDelay || nextDelay === erasingDelay)
      // update displayed text
      setTextContent(states[stateIndex].text)
      // advance to next text state
      setStateIndex(nextIndex)
    }, delay)

    // cleanup
    return () => clearTimeout(timeout)
  }, [states, stateIndex, erasingDelay, typingDelay, loop, textArray])

  return (
    <>
      <StyledCursor stopped={stopped} typing={typing}>
        {textContent}
      </StyledCursor>
    </>
  )
}

export default Typewriter

const blinkAnimation = keyframes`
  0% {
    background: #ccc;
  }
  49% {
    background: #ccc;
  }
  50% {
    background: transparent;
  }
  99% {
    background: transparent;
  }
  100% {
    background: #ccc;
  }
`

interface IStyledCursor {
  typing: boolean
  stopped: boolean
}

const StyledCursor = styled.span<IStyledCursor>`
  display: inline-flex;

  &::after {
    flex: 1;
    content: '';
    display: ${({ stopped }) => stopped && 'none'};
    background: #ccc;
    margin: 0 0 0 0.1em;
    width: 3px;
    animation: ${({ typing }) =>
      typing
        ? 'none'
        : css`
            ${blinkAnimation} 1s infinite
          `};
  }
`
