import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useLocalStorage from '../hooks/useLocalStorage'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'
import MediaQuery from '../utils/MediaQuery'

export default function CookieBanner() {
  const { hidden, consent, handleAccept } = useLocalStorage(
    'oreville-cookie-consent',
    '1',
    googleAnalytics
  )

  function googleAnalytics() {
    window.ga =
      window.ga ||
      function () {
        ;(ga.q = ga.q || []).push(arguments)
      }
    ga.l = +new Date()
    ga('create', 'UA-144233609-1', 'auto')
    ga('send', 'pageview')
  }

  return (
    <>
      {!hidden && !consent && (
        <StyledBackground as="div">
          <StyledContainer>
            <StyledWrapper>
              <StyledText>
                We use cookies to ensure we deliver the best user experience on
                our website.&nbsp;
                <StyledLink to="/cookies">View our Cookie Policy</StyledLink>
              </StyledText>
              <StyledButton onClick={handleAccept}>Got it!</StyledButton>
            </StyledWrapper>
          </StyledContainer>
        </StyledBackground>
      )}
    </>
  )
}
const StyledBackground = styled(Background)`
  position: fixed;
  inset: auto 0 0 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
`
const StyledContainer = styled(Container)`
  padding-block: 30px;

  @media ${MediaQuery.medium} {
    padding-block: 2em;
  }
`
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media ${MediaQuery.medium} {
    flex-direction: unset;
  }
`
const StyledText = styled.p`
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;

  @media ${MediaQuery.medium} {
    font-size: var(--body-font-size-1);
  }
`
const StyledLink = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.4s;

  &:hover,
  &:focus {
    color: #fff;
  }
`
const StyledButton = styled.button`
  align-self: start;
  appearance: none;
  border: none;
  background: rgba(255, 255, 255, 0.8);
  color: rgb(14, 16, 38);
  font-size: 13px;
  font-weight: 600;
  padding: 0.6em 1.7em;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.4s;

  &:hover,
  &:focus {
    background: #fff;
  }

  @media ${MediaQuery.medium} {
    align-self: unset;
    font-size: var(--body-font-size-0);
  }
`
