import React, { Fragment } from 'react'
import styled from 'styled-components'
import ClientList from '../components/ClientList'
import Heading from '../components/Heading'
import TestimonialList from '../components/TestimonialList'
import logo_angry_birds from '../images/clients/logo_angry_birds.svg'
import logo_disney from '../images/clients/logo_disney.svg'
import logo_microsoft from '../images/clients/logo_microsoft.svg'
import logo_minecraft from '../images/clients/logo_minecraft.svg'
import logo_mojang_studios from '../images/clients/logo_mojang_studios.svg'
import logo_rovio from '../images/clients/logo_rovio.svg'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'
import MediaQuery from '../utils/MediaQuery'

export default function Clients() {
  return (
    <StyledBackground id="clients">
      <StyledContainer>
        <Heading
          headline="Our Clients"
          subheadline="World leading brands."
          body={
            <Fragment>
              We are proud to work with <strong>industry-leading</strong>
              &nbsp;organisations to forge impactful experiences for the world.
              Pushing the bounds, we aspire to surpass the needs and
              expectations for our clients and consumers.
            </Fragment>
          }
        />
        <ClientList
          clients={[
            {
              src: logo_microsoft,
              alt: 'Microsoft Logo',
            },
            {
              src: logo_mojang_studios,
              alt: 'Mojang Studios Logo',
            },
            {
              src: logo_minecraft,
              alt: 'Minecraft Logo',
            },
            {
              src: logo_rovio,
              alt: 'Rovio Logo',
            },
            {
              src: logo_angry_birds,
              alt: 'Angry Birds Logo',
            },
            {
              src: logo_disney,
              alt: 'Disney Logo',
            },
          ]}
        />
        <StyledTestimonialList
          testimonials={[
            {
              testimonial:
                'The team at Oreville were a dream to work with. They dove deep into our brand, understanding the aesthetic and narrative breadth and gave it a fresh take.',
              companyName: 'Rovio',
            },
            {
              testimonial:
                'Oreville Studios are fantastic to work with! They really understand Minecraft fans and have created some of the most engaging player experiences we have seen in the Minecraft Marketplace.',
              companyName:
                'Todd Stevens, Director of Partner Content Publishing, Mojang',
            },
            // {
            //   testimonial:
            //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sapien nulla, condimentum id lacus eget, pulvinar venenatis neque. Sed odio ex, vestibulum non neque et, pulvinar eleifend augue.',
            //   companyName: 'Company',
            // },
            // {
            //   testimonial:
            //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sapien nulla, condimentum id lacus eget, pulvinar venenatis neque. Sed odio ex, vestibulum non neque et, pulvinar eleifend augue.',
            //   companyName: 'Company',
            // },
          ]}
        />
      </StyledContainer>
    </StyledBackground>
  )
}

const StyledBackground = styled(Background)`
  background: #0e0517;
`
const StyledContainer = styled(Container)`
  grid-row-gap: 4em;

  p {
    color: rgba(255, 255, 255, 0.5);
  }

  @media ${MediaQuery.medium} {
    grid-row-gap: 8em;
  }
`
const StyledTestimonialList = styled(TestimonialList)`
  @media ${MediaQuery.medium} {
    max-width: 80%;
  }
`
