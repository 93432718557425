import React, { Fragment } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CookieBanner from './components/CookieBanner'
import ScrollToTop from './components/ScrollToTop'
import Footer from './layouts/Footer'
import Cookies from './pages/Cookies'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Privacy from './pages/Privacy'
import Store from './pages/Store'
import Terms from './pages/Terms'
import GlobalStyles from './styles/GlobalStyles'

const App: React.FC = () => {
  return (
    <Fragment>
      <GlobalStyles />
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/store" component={Store} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/cookies" component={Cookies} />
          <Route path="/" component={NotFound} />
        </Switch>
        <Footer />
        <CookieBanner />
      </BrowserRouter>
    </Fragment>
  )
}

export default App
