import React from 'react'
import styled from 'styled-components'
import MediaQuery from '../utils/MediaQuery'

interface ITestimonialProps {
  testimonial: string
  companyName: string
}

interface ITestimonialListProps {
  className?: string
  testimonials: Array<ITestimonialProps>
}

export default function TestimonialList({
  className,
  testimonials,
}: ITestimonialListProps) {
  return (
    <StyledWrapper className={className}>
      {/* <StyledHeading>What our clients say</StyledHeading> */}
      <StyledTestimonialLayout>
        <StyledHeading>What our clients say</StyledHeading>
        {testimonials.map(({ testimonial, companyName }) => (
          <StyledCompanyTestimonial>
            "{testimonial}"
            <StyledCompanyName> &mdash; {companyName}</StyledCompanyName>
          </StyledCompanyTestimonial>
        ))}
      </StyledTestimonialLayout>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  text-align: center;
`
const StyledHeading = styled.h2`
  // margin: 0;
  // display: flex;
  // align-items: center;

  // &::before,
  // &::after {
  //   content: '';
  //   flex: 1;
  //   border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  // }

  // &:not(:empty)::before {
  //   margin-right: 1em;
  // }

  // &:not(:empty)::after {
  //   margin-left: 1em;
  // }

  font-size: 48px;
  text-transform: capitalize;

  @media ${MediaQuery.medium} {
    max-width: 60%;
    text-align: right;
    margin-left: auto;
  }
`
const StyledTestimonialLayout = styled.div`
  // display: grid;
  gap: 2em;
  font-size: 19px;

  @media ${MediaQuery.medium} {
    // grid-template-columns: repeat(3, 1fr);
    // gap: 4em;
    columns: 2;
  }
`
const StyledCompanyTestimonial = styled.p`
  // margin: 0;
  display: inline-block;
  margin: 0 0 2em;
  padding: 3em 30px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 14px;
  // break-inside: avoid;
`
const StyledCompanyName = styled.span`
  color: #fff;
`
