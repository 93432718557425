import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Heading from '../components/Heading'
import HomeHeader from '../layouts/headers/HomeHeader'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'

const Terms: React.FC = () => {
  return (
    <Fragment>
      <HomeHeader />
      <StyledBackground>
        <StyledContainer>
          <Heading
            headline="Terms of Use"
            subheadline="Updated 31st July, 2020"
            ghostHeadline={false}
          />
          <div>
            <StyledSubtitle BorderBottom>
              PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
              SITE
            </StyledSubtitle>
            <StyledSubtitle>What's in these terms?</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                These terms tell you the rules for using our website
                orevillestudios.com (our site).
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>Who we are and how to contact us</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                orevillestudios.com is a site operated by Oreville Studios
                Limited ("We"). We are registered in England and Wales under
                company number 12185257 and have our registered office at
                Preston Park House, South Road, Brighton, East Sussex, United
                Kingdom, BN1 6SB.
              </StyledParagraph>
              <StyledParagraph>We are a limited company.</StyledParagraph>
              <StyledParagraph>
                To contact us, please fill out the contact form on our site,
                email us at{' '}
                <a href="mailto:contact@orevillestudios.com">
                  contact@orevillestudios.com
                </a>{' '}
                or tweet us on{' '}
                <a
                  href="//twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=@OrevilleStudios"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @OrevilleStudios
                </a>
                .
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              By using our site you accept these terms
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                By using our site, you confirm that you accept these terms of
                use and that you agree to comply with them.
              </StyledParagraph>
              <StyledParagraph>
                If you do not agree to these terms, you must not use our site.
              </StyledParagraph>
              <StyledParagraph>
                We recommend that you print a copy of these terms for future
                reference.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              There are other terms that may apply to you
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                These terms of use refer to the following additional terms,
                which also apply to your use of our site:
              </StyledParagraph>
              <StyledUnorderedList>
                <li>
                  Our <Link to="/privacy">Privacy Policy</Link>. See further
                  under How we may use your personal information.
                </li>
                <li>
                  Our <Link to="/cookies">Cookie Policy</Link>, which sets out
                  information about the cookies on our site.
                </li>
              </StyledUnorderedList>
              <StyledParagraph>
                If we make goods or content available for purchase from our
                site, separate terms and conditions of supply will apply to the
                sales.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>We may make changes to these terms</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                We amend these terms from time to time. Every time you wish to
                use our site, please check these terms to ensure you understand
                the terms that apply at that time. These terms were most
                recently updated on 31st July, 2020.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>We may make changes to our site</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                We may update and change our site from time to time to reflect
                changes to, for example, our activities or content which may be
                available.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>We may suspend or withdraw our site</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                Our site is made available free of charge.
              </StyledParagraph>
              <StyledParagraph>
                We do not guarantee that our site, or any content on it, will
                always be available or be uninterrupted. We may suspend or
                withdraw or restrict the availability of all or any part of our
                site for business and operational reasons. We will try to give
                you reasonable notice of any suspension or withdrawal.
              </StyledParagraph>
              <StyledParagraph>
                You are also responsible for ensuring that all persons who
                access our site through your internet connection are aware of
                these terms of use and other applicable terms and conditions,
                and that they comply with them.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              We may transfer this agreement to someone else
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                We may transfer our rights and obligations under these terms to
                another organisation. We will always tell you in writing if this
                happens and we will ensure that the transfer will not affect
                your rights under the contract.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              You must keep your account details safe
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                Should we introduce any login functionality, if you choose, or
                you are provided with, a user identification code, password or
                any other piece of information as part of our security
                procedures, you must treat such information as confidential. You
                must not disclose it to any third party.
              </StyledParagraph>
              <StyledParagraph>
                We have the right to disable any user identification code or
                password, whether chosen by you or allocated by us, at any time,
                if in our reasonable opinion you have failed to comply with any
                of the provisions of these terms of use.
              </StyledParagraph>
              <StyledParagraph>
                If you know or suspect that anyone other than you knows your
                user identification code or password, you must promptly notify
                us via our website or by emailing us.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              How you may use material on our site
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                We are the owner or the licensee of all intellectual property
                rights in our site, and in the material published on it. Those
                works are protected by copyright laws and treaties around the
                world. All such rights are reserved.
              </StyledParagraph>
              <StyledParagraph>
                You may print off one copy, and may download extracts, of any
                page(s) from our site for your personal use.
              </StyledParagraph>
              <StyledParagraph>
                You must not modify the paper or digital copies of any materials
                you have printed off or downloaded in any way, and you must not
                use any artwork, music, photographs, video or audio sequences or
                any graphics separately from any accompanying text.
              </StyledParagraph>
              <StyledParagraph>
                Our status (and that of any identified contributors) as the
                authors of content on our site must always be acknowledged.
              </StyledParagraph>
              <StyledParagraph>
                You must not use any part of the content on our site for
                commercial purposes without obtaining a licence to do so from us
                or our licensors.
              </StyledParagraph>
              <StyledParagraph>
                If you print off, copy or download any part of our site in
                breach of these terms of use, your right to use our site will
                cease immediately and you must, at our option, return or destroy
                any copies of the materials you have made.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              Do not rely on information on this site
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                The content on our site is provided for general information
                only. Nothing in it is intended to amount to advice on which you
                should rely.
              </StyledParagraph>
              <StyledParagraph>
                Although we make reasonable efforts to update the information on
                our site, we make no representations, warranties or guarantees,
                whether express or implied, that the content on our site is
                accurate, complete or up to date.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              We are not responsible for websites we link to
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                Where our site contains links to other sites and resources
                provided by third parties, these links are provided for your
                information only. Such links should not be interpreted as
                approval by us of those linked websites or information you may
                obtain from them.
              </StyledParagraph>
              <StyledParagraph>
                We have no control over the contents of those sites or
                resources.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              User-generated content is not approved by us
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                This website may include information and materials uploaded by
                other users of the site, including to any bulletin boards and/or
                chat rooms, should we introduce such features. This information
                and these materials have not been verified or approved by us.
                The views expressed by other users on our site do not represent
                our views or values.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              How to complain about content uploaded by other users
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                If you wish to complain about content uploaded by other users,
                please contact us through the website.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              Our responsibility for loss or damage suffered by you
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                <strong>Whether you are a consumer or a business user:</strong>
              </StyledParagraph>
              <StyledUnorderedList>
                <li>
                  We do not exclude or limit in any way our liability to you
                  where it would be unlawful to do so. This includes liability
                  for death or personal injury caused by our negligence or the
                  negligence of our employees, agents or subcontractors and for
                  fraud or fraudulent misrepresentation.
                </li>
                <li>
                  Different limitations and exclusions of liability will apply
                  to liability arising as a result of the supply of any products
                  or content to you, which will be set out in any separate terms
                  and conditions of supply.
                </li>
                <li>
                  We do not represent that content available on or through our
                  site is appropriate for use or available in all locations.
                </li>
              </StyledUnorderedList>
              <StyledParagraph>
                <strong>If you are a business user:</strong>
              </StyledParagraph>
              <StyledUnorderedList>
                <li>
                  We exclude all implied conditions, warranties, representations
                  or other terms that may apply to our site or any content on
                  it.
                </li>
                <li>
                  We will not be liable to you for any loss or damage, whether
                  in contract, tort (including negligence), breach of statutory
                  duty, or otherwise, even if foreseeable, arising under or in
                  connection with:
                  <StyledUnorderedList>
                    <li>use of, or inability to use, our site; or</li>
                    <li>
                      use of or reliance on any content displayed on our site.
                    </li>
                  </StyledUnorderedList>
                </li>
                <li>
                  In particular, we will not be liable for:
                  <StyledUnorderedList>
                    <li>loss of profits, sales, business, or revenue;</li>
                    <li>business interruption;</li>
                    <li>loss of anticipated savings;</li>
                    <li>
                      loss of business opportunity, goodwill or reputation; or
                    </li>
                    <li>any indirect or consequential loss or damage.</li>
                  </StyledUnorderedList>
                </li>
              </StyledUnorderedList>
              <StyledParagraph>
                <strong>If you are a consumer user:</strong>
              </StyledParagraph>
              <StyledUnorderedList>
                <li>
                  Please note that we only provide our site for domestic and
                  private use. You agree not to use our site for any commercial
                  or business purposes, and we have no liability to you for any
                  loss of profit, loss of business, business interruption, or
                  loss of business opportunity.
                </li>
                <li>
                  If defective digital content that we have supplied damages a
                  device or digital content belonging to you and this is caused
                  by our failure to use reasonable care and skill, we will
                  either repair the damage or pay you compensation. However, we
                  will not be liable for any damage that you could have avoided
                  by following our advice to apply an update offered to you free
                  of charge or for damage that was caused by you failing to
                  correctly follow installation instructions or to have in place
                  the minimum system requirements advised by us.
                </li>
              </StyledUnorderedList>
            </StyledBlock>
            <StyledSubtitle>
              How we may use your personal information
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                We will only use your personal information as set out in our{' '}
                <Link to="/privacy">Privacy Policy</Link>.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>Uploading content to our site</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                Should we introduce any feature that allows you to upload
                content to our site, or to make contact with other users of our
                site, you must comply with the standards set out in an
                Acceptable Use Policy, which we will introduce should we enable
                such a feature.
              </StyledParagraph>
              <StyledParagraph>
                If this feature is introduced, you warrant that any contribution
                is to comply with those standards, and you will be liable to us
                and indemnify us for any breach of that warranty. This means you
                will be responsible for any loss or damage we suffer as a result
                of your breach of this warranty.
              </StyledParagraph>
              <StyledParagraph>
                Any content you upload to our site will be considered
                non-confidential and non-proprietary. You retain all of your
                ownership rights in your content, but you are required to grant
                us a limited licence to use, store and copy that content and to
                distribute and make it available to third parties. The rights
                you license to us are described below, under “Rights you are
                giving us to use material you upload”.
              </StyledParagraph>
              <StyledParagraph>
                We also have the right to disclose your identity to any third
                party who is claiming that any content posted or uploaded by you
                to our site constitutes a violation of their intellectual
                property rights, or of their right to privacy.
              </StyledParagraph>
              <StyledParagraph>
                We have the right to remove any posting you make on our site if,
                in our opinion, your post does not comply with the content
                standards set out in any Acceptable Use Policy.
              </StyledParagraph>
              <StyledParagraph>
                You are solely responsible for securing and backing up your
                content.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              Rights you are giving us to use material you upload
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                When you upload or post content to our site, you grant us the
                following rights to use that content:
              </StyledParagraph>
              <StyledUnorderedList>
                <li>
                  To display that content within our site for others to view and
                  interact with.
                </li>
              </StyledUnorderedList>
            </StyledBlock>
            <StyledSubtitle>
              We are not responsible for viruses and you must not introduce them
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                We do not guarantee that our site will be secure or free from
                bugs or viruses.
              </StyledParagraph>
              <StyledParagraph>
                You are responsible for configuring your information technology,
                computer programmes and platform to access our site. You should
                use your own virus protection software.
              </StyledParagraph>
              <StyledParagraph>
                You must not misuse our site by knowingly introducing viruses,
                trojans, worms, logic bombs or other material that is malicious
                or technologically harmful. You must not attempt to gain
                unauthorised access to our site, the server on which our site is
                stored or any server, computer or database connected to our
                site. You must not attack our site via a denial-of-service
                attack or a distributed denial-of service attack. By breaching
                this provision, you would commit a criminal offence under the
                Computer Misuse Act 1990. We will report any such breach to the
                relevant law enforcement authorities and we will co-operate with
                those authorities by disclosing your identity to them. In the
                event of such a breach, your right to use our site will cease
                immediately.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>Rules about linking to our site</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                You may link to our home page, provided you do so in a way that
                is fair and legal and does not damage our reputation or take
                advantage of it.
              </StyledParagraph>
              <StyledParagraph>
                You must not establish a link in such a way as to suggest any
                form of association, approval or endorsement on our part where
                none exists.
              </StyledParagraph>
              <StyledParagraph>
                You must not establish a link to our site in any website that is
                not owned by you.
              </StyledParagraph>
              <StyledParagraph>
                Our site must not be framed on any other site, nor may you
                create a link to any part of our site other than the home page.
              </StyledParagraph>
              <StyledParagraph>
                We reserve the right to withdraw linking permission without
                notice.
              </StyledParagraph>
              <StyledParagraph>
                If you wish to link to or make any use of content on our site
                other than that set out above, please contact us with details so
                that we can consider your request. You must obtain our specific
                permission before linking to or making any use of content on our
                site in any way that is not otherwise permitted under these
                terms.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>
              Which country's laws apply to any disputes?
            </StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                If you are a consumer, these terms of use, their subject matter
                and their formation (and any non-contractual disputes or claims)
                are governed by English law. If you live in the United Kingdom,
                you can bring legal proceedings in either the English courts or
                if you live in another country of the United Kingdom, in your
                home country’s courts. If you live in another country outside
                the United Kingdom, you can bring proceedings in your home
                country’s courts or the English courts.
              </StyledParagraph>
              <StyledParagraph>
                If you are a business, these terms of use, their subject matter
                and their formation (and any non-contractual disputes or claims)
                are governed by English law. We both agree to the exclusive
                jurisdiction of the courts of England and Wales.
              </StyledParagraph>
            </StyledBlock>
            <StyledSubtitle>Our trade marks are registered</StyledSubtitle>
            <StyledBlock>
              <StyledParagraph>
                “Oreville Studios” and the Oreville Studios logo are
                unregistered trade marks of Oreville Studios Limited. You are
                not permitted to use them without our approval, unless they are
                part of material you are using as permitted under “How you may
                use material on our site” above.
              </StyledParagraph>
            </StyledBlock>
          </div>
        </StyledContainer>
      </StyledBackground>
    </Fragment>
  )
}

export default Terms

const StyledBackground = styled(Background)``
const StyledContainer = styled(Container)`
  display: grid;
  grid-row-gap: 8em;
`
const StyledUnorderedList = styled.ul`
  list-style: disc;
  margin: 1em 0;

  & > li {
    margin: 0 0 0.8em;
    padding: 0 0 0 0.4em;
    line-height: 1.5em;
  }
`

interface IStyledSubtitle {
  BorderBottom?: boolean
}

const StyledSubtitle = styled.h3<IStyledSubtitle>`
  ${({ BorderBottom }) =>
    BorderBottom &&
    css`
      display: block;
      padding: 0 0 1em;
      border-bottom: 1px solid var(--secondary-font-color-1);
    `}
`
const StyledParagraph = styled.p`
  color: var(--secondary-font-color-1);
`
const StyledBlock = styled.div`
  border-left: 4px solid var(--secondary-font-color-1);
  padding-left: 1em;
`
