import React, { useState } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import { StyledLink } from '../styles/Link'
import MediaQuery from '../utils/MediaQuery'

interface ILinkData {
  href: string
  name: string
}

interface INavigation {
  className?: string
  links: Array<ILinkData>
}

const Navigation: React.FC<INavigation> = ({ className, links }) => {
  const [toggle, setToggle] = useState(false)

  function handleToggle() {
    if (toggle === true) {
      setToggle(!toggle)
    }
  }

  return (
    <StyledNav className={className}>
      <GlobalStyle toggle={toggle} />
      <StyledBurgerIcon toggle={toggle} onClick={() => setToggle(!toggle)}>
        <svg viewBox="20 20 60 60" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="30" />
          <path className="path line--1" d="M0 70l28-28c2-2 2-2 7-2h64" />
          <path className="path line--2" d="M0 50h99" />
          <path className="path line--3" d="M0 30l28 28c2 2 2 2 7 2h64" />
        </svg>
      </StyledBurgerIcon>
      <StyledList toggle={toggle}>
        {links.map(({ href, name }, index) => (
          <StyledItem key={index}>
            <StyledLink href={href} onClick={handleToggle}>
              {name}
            </StyledLink>
          </StyledItem>
        ))}
      </StyledList>
    </StyledNav>
  )
}

export default Navigation

interface IGlobalStyle {
  toggle: boolean
}

const GlobalStyle = createGlobalStyle<IGlobalStyle>`
  body {
    overflow: ${({ toggle }) => (toggle ? ' hidden' : 'initial')};
  }
`

const StyledNav = styled.nav`
  grid-area: nav;
  display: flex;
  flex-direction: column;
`

interface IStyledBurgerIcon extends IGlobalStyle {}

const StyledBurgerIcon = styled.button<IStyledBurgerIcon>`
  align-self: flex-end;
  width: 3.5em;
  height: 3.5em;
  background: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;
  position: relative;
  z-index: 26;

  svg {
    &:hover,
    &:focus-visible {
      circle {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .path {
    fill: none;
    stroke: #fff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);

    ${({ toggle }) =>
      toggle &&
      css`
        transform: translateX(30px);
      `}
  }

  circle {
    fill: rgba(255, 255, 255, 0.1);
    transition: all 300ms cubic-bezier(0.79, .14, .15, .86);
    transform-origin: 80% 80%;
    transform: scale(0);
    opacity: 0;
  }

  .line--1,
  .line--3 {
    --total-length: 111.22813415527344;
    --offset: ${({ toggle }) =>
      toggle ? '-16.9705627485' : '-50.22813415527344'};
  }
  .line--2 {
    --total-length: 99;
    ${({ toggle }) =>
      toggle &&
      css`
        --length: 0;
        --offset: -20;
      `}
  }

  @media ${MediaQuery.large} {
    display: none;
  }
`

interface IStyledList extends IGlobalStyle {}

const StyledList = styled.ul<IStyledList>`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  background: #0e0517;

  margin: 0;
  padding: 0;

  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: ${({ toggle }) => (toggle ? '100vh' : '0')};
  transition: height 0.8s ease;

  @media ${MediaQuery.large} {
    all: unset;
    display: flex;
    gap: 2em;
  }
`
const StyledItem = styled.li`
  display: inline-block;
  font-size: var(--body-font-size-2);
  font-weight: 600;
  letter-spacing: 0.008em;
`
