import styled from 'styled-components'
import MediaQuery from '../utils/MediaQuery'

export const Container = styled.div`
  // margin: 0 auto;
  // padding: 0 1em;
  // width: 100%;
  // max-width: 64em;

  // @media ${MediaQuery.large} {
  //   max-width: 81.25em;
  // }

  padding-block: 150px;

  display: grid;
  grid-template-columns:
    [cover-start] 30px [full-start] minmax(0, 1fr) [main-start] minmax(
      0,
      81.25em
    )
    [main-end] minmax(0, 1fr) [full-end] 30px [cover-end];
  justify-items: center;

  > * {
    grid-column: main;
  }

  @media ${MediaQuery.large} {
    padding-block: 205px;
  }
`
