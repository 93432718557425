import React from 'react'
import styled from 'styled-components'

interface ILegalProps {
  copyright: string
}

export default function Legal({ copyright }: ILegalProps) {
  return (
    <StyledWrapper>
      Copyright &copy; {new Date().getFullYear()} {copyright}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.span`
  display: inline-block;
  color: var(--secondary-font-color-1);
`
