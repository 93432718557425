import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

interface ICTA extends IButton {
  className?: string
  href: string
}

const CTA: React.FC<ICTA> = ({ className, href, children, ...props }) => {
  return (
    <StyledWrapper className={className}>
      {/^https?:\/\//.test(href) ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <Button {...props}>{children}</Button>
        </a>
      ) : (
        <Link to={href}>
          <Button {...props}>{children}</Button>
        </Link>
      )}
    </StyledWrapper>
  )
}

export default CTA

const StyledWrapper = styled.div`
  display: inline-block;

  & > a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }
`

interface IButton extends IStyledButton {}

const Button: React.FC<IButton> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>
}

const Shine = keyframes`
    0%, 60% {
      left: -75px;
    }
    100% {
      left: 120%;
    }
  `

interface IStyledButton {
  secondary?: boolean
  animate?: boolean
}

const StyledButton = styled.span<IStyledButton>`
  display: block;
  border: 0;
  cursor: pointer;
  padding: 1em 4em;
  font-size: var(--body-font-size-2);
  font-weight: 600;
  background: ${({ secondary }) =>
    secondary
      ? 'linear-gradient(90deg, #4e5ffc, #41a3f2, #4e5ffc)'
      : '#faf07b'};
  background-size: 300%;
  background-position: left;
  overflow: hidden;
  color: ${({ secondary }) => (secondary ? '#fff' : '#df7b00')};
  border-radius: 50px;
  transition: all 300ms;
  transition-property: transform, background-position;
  position: relative;

  ${({ animate }) =>
    animate &&
    css`
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: -50px;
        background: rgba(255, 255, 255, 0.2);
        height: 150px;
        z-index: 1;
        animation: ${Shine} 3s infinite linear;
      }

      &::after {
        width: 3.125em;
        transform: rotate(35deg);
      }

      &::before {
        width: 0.938em;
        transform: rotate(35deg) translateX(1.094em);
      }
    `}

  &:hover,
  &:focus {
    background-position: right;
  }
`
