import { useState } from 'react'

function useLocalStorage(key: string, value: string, callback: Function) {
  const [hidden, setHidden] = useState(false)
  const consentData = localStorage.getItem(key)
  let consent

  if (consentData === '1') {
    consent = true
    callback()
  }

  function handleAccept() {
    localStorage.setItem(key, value)
    setHidden(true)
  }

  function handleDecline() {
    setHidden(true)
  }

  return {
    hidden,
    consent,
    handleAccept,
    handleDecline,
  }
}

export default useLocalStorage
