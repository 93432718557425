import styled from 'styled-components'

export const StyledLink = styled.a`
  display: inline-block;
  color: var(--primary-font-color-1);
  will-change: transform;
  position: relative;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    background: var(--primary-font-color-1);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  &:hover::before,
  &:focus::before {
    visibility: visible;
    transform: scaleX(0.5);
  }
`
