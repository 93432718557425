import { createContext } from 'react'

interface IFormContext {
  error?: boolean
  feedback?: string
  visible?: boolean
  validation?: any
  handleSubmit?: any
  handleChange?: any
}

export const FormContext = createContext<IFormContext>({})
