import React from 'react'
import styled from 'styled-components'
import MediaQuery from '../utils/MediaQuery'

interface IClientProps {
  src: string
  alt: string
}

interface IClientListProps {
  className?: string
  clients: Array<IClientProps>
}

export default function ClientList({
  className,
  clients,
  ...props
}: IClientListProps) {
  return (
    <StyledWrapper className={className}>
      {clients.map(({ src, alt }) => (
        <StyledLogo src={src} alt={alt} {...props} />
      ))}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  width: 100%;
  justify-self: center;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
  gap: 50px;
  align-items: center;

  @media ${MediaQuery.medium} {
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
    gap: 5em;
  }
`
const StyledLogo = styled.img``
