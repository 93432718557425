import React from 'react'
import styled from 'styled-components'

interface IBadgeProps extends IStyledProps {
  className?: string
  title: string
}

export default function Badge({ className, title, ...props }: IBadgeProps) {
  return (
    <StyledBadge className={className} {...props}>
      {title}
    </StyledBadge>
  )
}

interface IStyledProps {
  newContent?: boolean
  warning?: boolean
}

const StyledBadge = styled.span<IStyledProps>`
  font-family: var(--primary-font-1);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.4em 0.8em;
  border-radius: 5px;

  background: ${({ newContent, warning }) => {
    if (newContent) return 'rgba(0, 242, 195, .2)'
    else if (warning) return 'red'
    return '#ccc'
  }};

  color: ${({ newContent, warning }) => {
    if (newContent) return '#00f2c3'
    else if (warning) return '#fff'
    return '#000'
  }};
`
