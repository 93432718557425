import React from 'react'
import styled from 'styled-components'
import logo from '../images/logo.svg'

interface ILogoProps {
  href?: string
  src?: string
  alt?: string
  className?: string
}

export default function Logo({
  href = '/',
  src = logo,
  alt = 'Oreville Studios Logo',
  className,
}: ILogoProps) {
  return (
    <a href={href} className={className}>
      <StyledLogo src={src} alt={alt} />
    </a>
  )
}

const StyledLogo = styled.img`
  height: 3rem;
`
