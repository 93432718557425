import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Legal from '../components/Legal'
import Logo from '../components/Logo'
import Social from '../components/Social'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'
import MediaQuery from '../utils/MediaQuery'

export default function Footer() {
  return (
    <StyledBackground as="footer">
      <StyledContainer>
        <StyledWrapper>
          <div className="logo">
            <Logo />
            <Social
              footer
              icons={[
                {
                  icon: ['fab', 'discord'],
                  href: '//discord.gg/dsNCAnM',
                  ariaLabel: 'Discord',
                },
                {
                  icon: ['fab', 'twitter'],
                  href: '//twitter.com/orevillestudios',
                  ariaLabel: 'Twitter',
                },
                {
                  icon: ['fab', 'snapchat-ghost'],
                  href: '//www.snapchat.com/add/orevillestudios',
                  ariaLabel: 'Snapchat',
                },
                {
                  icon: ['fab', 'youtube'],
                  href: '//youtube.com/orevillestudios',
                  ariaLabel: 'YouTube',
                },
                {
                  icon: ['fab', 'tiktok'],
                  href: '//www.tiktok.com/@orevillestudios',
                  ariaLabel: 'TikTok',
                },
                {
                  icon: ['fab', 'facebook'],
                  href: '//facebook.com/orevillestudios',
                  ariaLabel: 'Facebook',
                },
                {
                  icon: ['fab', 'instagram'],
                  href: '//instagram.com/orevillestudios',
                  ariaLabel: 'Instagram',
                },
              ]}
            />
            <Legal copyright="Oreville Studios Ltd. All Rights Reserved." />
          </div>
          <div className="column">
            <h3>Company</h3>
            <ul>
              <li>
                <a href="mailto:contact@orevillestudios.com">Contact</a>
              </li>
              <li>
                <a href="mailto:press@orevillestudios.com">Press</a>
              </li>
              <li>
                <a
                  href="//www.bucketofcrabs.net/c/orevillestudios"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </a>
              </li>
            </ul>
          </div>
          <div className="column">
            <h3>Legal</h3>
            <ul>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Use</Link>
              </li>
              <li>
                <Link to="/cookies">Cookie Policy</Link>
              </li>
            </ul>
          </div>
          <div className="column">
            <h3>Resources</h3>
            <ul>
              <li>
                <a
                  href="//drive.google.com/drive/folders/1m4-BtSDkT6tVWr3xy7eSHsbGrKgF1U8o?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Media Kit
                </a>
              </li>
            </ul>
          </div>
          <div className="column">
            <h3>Support</h3>
            <ul>
              <li>
                <a
                  href="//discord.gg/dsNCAnM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discord Support
                </a>
              </li>
              <li>
                <a href="mailto:support@orevillestudios.com">Email Support</a>
              </li>
            </ul>
          </div>
        </StyledWrapper>
      </StyledContainer>
    </StyledBackground>
  )
}

const StyledBackground = styled(Background)`
  background: #0e0517;
`
const StyledContainer = styled(Container)`
  & {
    padding-top: 100px;
  }
`
const StyledWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em 1em;
  font-size: 15px;

  @media ${MediaQuery.medium} {
    grid-template-columns: repeat(7, 1fr);
    font-size: var(--body-font-size-1);
  }

  .logo {
    // width: 550px;
    // height: 180px;
    grid-column: span 2;

    @media ${MediaQuery.medium} {
      // grid-column: unset;
      grid-column: span 3;
    }

    display: flex;
    flex-direction: column;
    gap: 1.5em;

    & > *:last-child {
      margin-top: auto;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 1.2em;

    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;

      @media ${MediaQuery.medium} {
        font-size: 17px;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      padding-bottom: 0.6em;

      &:last-of-type {
        padding: 0;
      }

      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.5);
        transition: color 0.4s;

        &:hover,
        &:focus-visible {
          color: rgb(255, 255, 255);
        }
      }
    }
  }
`
