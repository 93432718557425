import React, { Fragment } from 'react'
import styled from 'styled-components'
import Heading from '../components/Heading'
import MediaObject from '../components/MediaObject'
import artIcon from '../images/wwd_art.svg'
import levelDesignIcon from '../images/wwd_level_design.svg'
import mechanicsIcon from '../images/wwd_mechanics.svg'
import modelsIcon from '../images/wwd_models.svg'
import soundIcon from '../images/wwd_sound.svg'
import videoIcon from '../images/wwd_video.svg'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'
import MediaQuery from '../utils/MediaQuery'

const WhatWeDo: React.FC = () => {
  return (
    <StyledBackground id="what-we-do">
      <StyledContainer>
        <Heading
          headline="What We Do"
          subheadline="Imagine. Create. Inspire."
          body={
            <Fragment>
              Oreville is a collective of exceptional talent hailing from
              diverse corners of the globe, each bringing a unique array of
              skills to the table. Since our inception, our unwavering mission
              has been to deliver{' '}
              <strong>impeccably crafted, cutting edge</strong> products. Driven
              by a shared purpose, we tirelessly endeavour to create
              awe-inspiring content that places the player at the forefront of
              every experience.
            </Fragment>
          }
        />
        <StyledMediaObjectWrapper>
          <MediaObject
            src={levelDesignIcon}
            alt="Level design icon"
            heading="Level Design"
            body="With a sharp eye for detail and a strong passion for design, we focus on every aspect of the world building process to create an immersive experience."
          />
          <MediaObject
            src={modelsIcon}
            alt="Models and animation icon"
            heading="Models &amp; Animation"
            body="We create premium 3D models and complex animations that truly enhance every aspect of the worlds and levels we produce."
          />
          <MediaObject
            src={artIcon}
            alt="Art icon"
            heading="Art"
            body="Capable of producing a wide variety of highly detailed ‘draw’ dropping artwork, we set the tone and ambiance surrounding you throughout your experience."
          />
          <MediaObject
            src={videoIcon}
            alt="Video icon"
            heading="Video"
            body="Lights, Camera, Action! Head over to Oreville Studios on YouTube, and be the first to watch sneak peeks of our upcoming projects!"
          />
          <MediaObject
            src={soundIcon}
            alt="Sounds and music icon"
            heading="Sounds &amp; Music"
            body="We aim to push the boundaries of what sound in Minecraft can be, delivering highly detailed sound design, original compositions, and studio quality voice acting."
          />
          <MediaObject
            src={mechanicsIcon}
            alt="Mechanics icon"
            heading="Mechanics"
            body="Our advanced mechanics work in harmony with our unique sound and level design to consume you within the depths of the adventure."
          />
        </StyledMediaObjectWrapper>
      </StyledContainer>
    </StyledBackground>
  )
}

export default WhatWeDo

const StyledBackground = styled(Background)``
const StyledContainer = styled(Container)`
  grid-row-gap: 8em;
`
const StyledMediaObjectWrapper = styled.div`
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));
  grid-gap: 5em 3em;
  width: 100%;

  @media ${MediaQuery.medium} {
    grid-template-columns: repeat(auto-fit, minmax(26em, 1fr));
  }
`
