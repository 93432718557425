import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { FormContext } from '../contexts/FormContext'
import MediaQuery from '../utils/MediaQuery'

const StyledCommon = css`
  width: 100%;
  // position: relative;
  z-index: 1;
  border: 0;
  margin: 0;
  appearance: none;
  outline: none;
  background: transparent;
  border-radius: inherit;
  padding: 0.8em 1.8em;
  color: #20254a;

  &:invalid {
    box-shadow: none;
  }
`

/**
|--------------------------------------------------
| Form Component
|--------------------------------------------------
*/

interface IForm {
  className?: string
}

export const Form: React.FC<IForm> = ({ className, children }) => {
  const formContext = useContext(FormContext)
  return (
    <StyledForm
      className={className}
      onSubmit={formContext.handleSubmit}
      noValidate
    >
      {children}
      {formContext.validation.feedback && <Validation />}
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  font-size: 18px;

  @media ${MediaQuery.medium} {
    font-size: var(--body-font-size-4);
  }
`

/**
|--------------------------------------------------
| FormGroup Component
|--------------------------------------------------
*/

interface IFormGroup {
  className?: string
}

export const FormGroup: React.FC<IFormGroup> = ({ className, children }) => {
  return <StyledFormGroup className={className}>{children}</StyledFormGroup>
}

const StyledFormGroup = styled.div`
  display: grid;
  grid-row-gap: 1em;
`

/**
|--------------------------------------------------
| Input Wrapper Component
|--------------------------------------------------
*/

interface IInputWrapper extends IStyledInputWrapper {
  className?: string
}

export const InputWrapper: React.FC<IInputWrapper> = ({
  className,
  textarea,
  children,
}) => {
  const formContext = useContext(FormContext)
  return (
    <StyledInputWrapper
      className={className}
      textarea={textarea}
      {...formContext.validation}
    >
      {children}
    </StyledInputWrapper>
  )
}

interface IStyledInputWrapper extends IStyledValidation {
  textarea?: boolean
}

const StyledInputWrapper = styled.div<IStyledInputWrapper>`
  display: flex;
  border-radius: 50px;
  background: #fff;
  position: relative;
  padding: 3px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    border-radius: inherit;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.3);
    mix-blend-mode: overlay;
  }

  ${({ error }) =>
    error &&
    css`
      border: 2px solid #e65c5c;
    `};

  ${({ textarea }) =>
    textarea &&
    css`
      border-radius: 35px;
    `}
`

/**
|--------------------------------------------------
| Input Component
|--------------------------------------------------
*/

interface IInput {
  type: string
  id: string
  name: string
  placeholder?: string
  value?: string
  autoComplete?: string
}

export const Input: React.FC<IInput> = ({
  type,
  id,
  name,
  placeholder,
  value,
  autoComplete = 'off',
}) => {
  const formContext = useContext(FormContext)
  return (
    <StyledInput
      type={type}
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={formContext.handleChange}
      autoComplete={autoComplete}
    />
  )
}

const StyledInput = styled.input`
  ${StyledCommon}
`

/**
|--------------------------------------------------
| Textarea Component
|--------------------------------------------------
*/

interface ITextarea {
  id: string
  name: string
  placeholder?: string
  rows?: number
  maxLength?: number
  value: string
}

export const Textarea: React.FC<ITextarea> = ({
  id,
  name,
  placeholder,
  rows,
  maxLength,
  value,
}) => {
  const charLimit = useMemo(() => {
    if (maxLength) {
      if (value.length >= maxLength) {
        return 'You have reached the maximum number of characters!'
      } else {
        return `${value.length} / ${maxLength}`
      }
    }
  }, [value, maxLength])
  const formContext = useContext(FormContext)
  return (
    <>
      <InputWrapper textarea>
        <StyledTextarea
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          rows={rows}
          onChange={formContext.handleChange}
          maxLength={maxLength}
        />
      </InputWrapper>
      {maxLength && <CharacterLimit charLimit={charLimit} />}
    </>
  )
}

const StyledTextarea = styled.textarea`
  ${StyledCommon}
  resize: vertical;
  min-height: 23em;
  max-height: 60em;
  padding: 1.1em 1.8em;
`

/**
|--------------------------------------------------
| CharacterLimit Component
|--------------------------------------------------
*/

interface ICharacterLimit {
  charLimit?: string
}

export const CharacterLimit: React.FC<ICharacterLimit> = ({ charLimit }) => {
  return <StyledCharacterLimit>{charLimit}</StyledCharacterLimit>
}

const StyledCharacterLimit = styled.span`
  // font-size: var(--body-font-size-4);
  color: var(--secondary-font-color-1);
  text-align: right;
`

/**
|--------------------------------------------------
| Button Component
|--------------------------------------------------
*/

interface IButton extends IStyledButton {
  className?: string
  as?: React.ElementType | keyof JSX.IntrinsicElements
  href?: string
  target?: string
}

export const Button: React.FC<IButton> = ({
  className,
  border,
  white,
  children,
  as,
  href,
  target,
}) => {
  return (
    <StyledButton
      className={className}
      border={border}
      as={as}
      href={href}
      target={target}
      white={white}
    >
      {children}
    </StyledButton>
  )
}

interface IStyledButton {
  border?: boolean
  white?: boolean
}

const StyledButton = styled.button<IStyledButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4em;
  border: 0;
  cursor: pointer;
  padding: 1.2em 4.6em;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(90deg, #4e5ffc, #41a3f2, #4e5ffc);
  background-size: 300%;
  background-position: left;
  color: #fff;
  border-radius: 50px;
  transition: background-position 300ms;
  position: relative;

  @media ${MediaQuery.medium} {
    font-size: var(--body-font-size-2);
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    background-position: right;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  ${({ border }) =>
    border &&
    css`
      box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.02);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        border-radius: inherit;
        box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.3);
        mix-blend-mode: overlay;
      }
    `}

  ${({ white }) =>
    white &&
    css`
      background: #ccc;
      color: #262d53;

      transition: all 300ms;

      &:hover,
      &:focus-visible {
        background: #fff;
      }
    `}
`

/**
|--------------------------------------------------
| Label Component
|--------------------------------------------------
*/

interface ILabel {
  htmlFor: string
  label: string
  required?: boolean
}

export const Label: React.FC<ILabel> = ({ htmlFor, label }) => {
  return (
    <StyledLabel htmlFor={htmlFor}>
      {label}
      {label && (
        <StyledRequired height="5" width="5">
          <circle cx="2.5" cy="2.5" r="2.5" fill="#ef434c" />
        </StyledRequired>
      )}
    </StyledLabel>
  )
}

const StyledLabel = styled.label`
  display: flex;
  // font-size: var(--body-font-size-4);
  font-weight: 500;
`
const StyledRequired = styled.svg`
  margin: 6px 0 0 8px;
`

/**
|--------------------------------------------------
| Validation Component
|--------------------------------------------------
*/

interface IValidation extends IStyledValidation {
  feedback?: string
}

export const Validation: React.FC<IValidation> = () => {
  const formContext = useContext(FormContext)
  return (
    <StyledValidation {...formContext.validation}>
      {formContext.validation.error ? (
        <StyledFontAwesomeIcon icon="times" />
      ) : (
        <StyledFontAwesomeIcon icon="check" />
      )}
      {formContext.validation.feedback}
    </StyledValidation>
  )
}

const errorAnimation = keyframes`
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
`

const successAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
`

interface IStyledValidation {
  error?: boolean
  visible?: boolean
}

const StyledValidation = styled.span<IStyledValidation>`
  font-size: 18px;
  text-align: center;
  color: ${({ error }) => (error ? '#e65c5c' : '#5cce71')};
  animation: 1s ${({ error }) => (error ? errorAnimation : successAnimation)};
  transition: opacity 0.5s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};

  @media ${MediaQuery.medium} {
    grid-column: span 2;
    font-size: var(--body-font-size-4);
  }
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: 0 0.4em 0;
`
