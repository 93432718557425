// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import Badge from '../components/Badge'
import { Button } from '../components/FormElements'
import Heading from '../components/Heading'
import Scroll from '../components/Scroll'
import Social from '../components/Social'
import Typewriter from '../components/Typewriter'
import { Background } from '../styles/Background'
import { Container } from '../styles/Container'
import MediaQuery from '../utils/MediaQuery'

export default function Hero() {
  return (
    <StyledBackground>
      <StyledContainer>
        <StyledWrapper>
          <StyledHeading
            headline="We Create "
            subheadline="Developing high quality content for the Minecraft Marketplace."
            ghostHeadline={false}
          >
            <Typewriter
              typingDelay={150}
              erasingDelay={50}
              newTextDelay={2000}
              textArray={['Games...', 'Adventures...', 'Experiences...']}
            />
          </StyledHeading>
          <Badge newContent title="New Release" />
          <StyledIframe
            title="Minecraft x The Incredibles DLC - Official Trailer"
            src="//www.youtube-nocookie.com/embed/pSnMxCgvahU"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></StyledIframe>
          <Button
            as="a"
            href="//www.minecraft.net/en-us/marketplace/pdp?id=5e1271b9-484c-4a1d-a9f4-5c919e8a0bbb"
            target="_blank"
            white
          >
            Play now
            {/* <FontAwesomeIcon icon="gamepad" /> */}
          </Button>
        </StyledWrapper>
        <StyledSocialEyebrow>Join our Community</StyledSocialEyebrow>
        <StyledSocial
          icons={[
            {
              icon: ['fab', 'discord'],
              href: '//discord.gg/dsNCAnM',
              ariaLabel: 'Discord',
            },
            {
              icon: ['fab', 'twitter'],
              href: '//twitter.com/orevillestudios',
              ariaLabel: 'Twitter',
            },
            {
              icon: ['fab', 'snapchat-ghost'],
              href: '//www.snapchat.com/add/orevillestudios',
              ariaLabel: 'Snapchat',
            },
            {
              icon: ['fab', 'youtube'],
              href: '//youtube.com/orevillestudios',
              ariaLabel: 'YouTube',
            },
            {
              icon: ['fab', 'tiktok'],
              href: '//www.tiktok.com/@orevillestudios',
              ariaLabel: 'TikTok',
            },
            {
              icon: ['fab', 'facebook'],
              href: '//facebook.com/orevillestudios',
              ariaLabel: 'Facebook',
            },
            {
              icon: ['fab', 'instagram'],
              href: '//instagram.com/orevillestudios',
              ariaLabel: 'Instagram',
            },
          ]}
        />
        <Scroll />
      </StyledContainer>
    </StyledBackground>
  )
}

const StyledBackground = styled(Background)``
const StyledContainer = styled(Container)`
  & {
    padding-top: 0;
  }
`
const StyledWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3em;

  margin-bottom: 3em;
`
const StyledHeading = styled(Heading)`
  text-align: center;
`
const StyledIframe = styled.iframe`
  width: 100%;
  border-radius: 14px;
  box-shadow: 0 0 78px 0 rgba(0, 0, 0, 0.8);
  aspect-ratio: 16 / 9;
`
const StyledSocialEyebrow = styled.h2`
  font-size: 25px;

  @media ${MediaQuery.medium} {
    font-size: 32px;
  }
`
const StyledSocial = styled(Social)`
  margin-bottom: 5em;
  justify-content: center;

  & > * {
    font-size: 25px;
  }
`
