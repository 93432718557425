import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faSnapchatGhost,
  faTiktok,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faChevronRight,
  faGamepad,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faDiscord,
  faTwitter,
  faSnapchatGhost,
  faYoutube,
  faTiktok,
  faFacebook,
  faInstagram,
  faArrowLeft,
  faArrowRight,
  faChevronRight,
  faTimes,
  faCheck,
  faGamepad
)
