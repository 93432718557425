import React from 'react'
import styled from 'styled-components'
import CTA from '../../components/CTA'
import Navigation from '../../components/Navigation'
import MediaQuery from '../../utils/MediaQuery'
import GlobalHeader from './GlobalHeader'

const StoreHeader: React.FC = () => {
  return (
    <GlobalHeader>
      <Navigation
        links={[
          { href: '#featured', name: 'Featured' },
          { href: '#recent-projects', name: 'Recent Projects' },
          { href: '#categories', name: 'Categories' },
        ]}
      />
      <StyledCTA href="/#contact" secondary>
        Contact
      </StyledCTA>
    </GlobalHeader>
  )
}

export default StoreHeader

const StyledCTA = styled(CTA)`
  grid-area: cta;
  justify-self: end;
  display: none;

  @media ${MediaQuery.large} {
    display: inline-block;
  }
`
